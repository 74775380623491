import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TclLogo from "../../assets/images/tcl_logo.png";
import IdeoLogo from "../../assets/images/ideo_logo.png";
import "./Login.sass";
import { getAuthorizationToken } from "../../api/auth";
import { ApplicationRoutes } from "../../app/routes";
import { SubmitButton } from "../../componentsShared/Buttons/SubmitButton/SubmitButton";
import { ErrorNotification } from "../../componentsShared/Notification/ErrorNotification/ErrorNotification";
import { useDispatch } from "react-redux";
import { setIsAuthorized } from "../Auth/authSlice";
import { hasTokenSet, setToken } from "../../utils/token";
import { TextInput } from "../../componentsShared/Inputs/TextInput/TextInput";
import { PasswordInput } from "../../componentsShared/Inputs/PasswordInput/PasswordInput";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const isLoggedIn = hasTokenSet();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ApplicationRoutes.prototypesList);
    }
  }, [navigate, isLoggedIn]);

  const handleLogin = (event: FormEvent) => {
    setIsSubmitting(true);
    event.preventDefault();

    getAuthorizationToken({ username, password }).then((response: any) => {
      if (response.errorMessage) {
        setIsSubmitting(false);
        setErrorMessage(response.errorMessage);
        return;
      }

      const { access_token, refresh_token, expires_in } = response;

      setToken({
        access_token,
        refresh_token,
        expires_in,
      });

      dispatch(setIsAuthorized(false));
      navigate(ApplicationRoutes.prototypesList);
      setIsSubmitting(false);
    });
  };

  return (
    <div className="Login">
      <img className="tcl-logo" src={TclLogo} alt="TCL logo" />
      <img className="ideo-logo" src={IdeoLogo} alt="IDEO logo" />
      <form onSubmit={handleLogin} className="login-form">
        {errorMessage && <ErrorNotification text={errorMessage} />}
        <TextInput value={username} required placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
        <PasswordInput value={password} onChange={(e) => setPassword(e.target.value)} />
        <SubmitButton text="Sign In" isSubmitting={isSubmitting} disabled={isSubmitting} />
      </form>
    </div>
  );
};
