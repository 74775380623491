import { FC, ReactNode } from "react";
import "./AssistantWrapper.sass";
import AsisstantBackground from "../../../assets/images/chat_bot_background.png";
import Buddy from "../../../assets/images/buddy.png";

type TProps = {
  children: ReactNode;
};

export const AssistantWrapper: FC<TProps> = ({ children }) => {
  return (
    <div className="AssistantWrapper">
      <div className="background" style={{ backgroundImage: `url(${AsisstantBackground})` }}>
        <div className="assistant-background" style={{ backgroundImage: `url(${Buddy})` }}>
          {children}
        </div>
      </div>
    </div>
  );
};
