import { productPlacementEndpointPath, get } from "./apiDetails";

// export const mockLocation = "1344 Clayton St, San Francisco, CA 94114";
// export const mockStreetNumber = "1344";
// export const mockStreetName = "Clayton St";
// export const mockStreetAptNumber = "";
// export const mockCity = "San Francisco";
// export const mockState = "CA";
// export const mockCountry = "US";
// export const mockZip = "94114";
// export const mockLatitude = 37.3833339;
// export const mockLongitude = -121.8967014;

// export const mockLocation = "2025 Gateway Pl, San Jose, CA 95110";
// export const mockStreetNumber = "2025";
// export const mockStreetName = "Gateway Pl";
// export const mockStreetAptNumber = "";
// export const mockCity = "San Jose";
// export const mockState = "CA";
// export const mockCountry = "US";
// export const mockZip = "95110";
// export const mockLatitude = 37.370661;
// export const mockLongitude = -121.924333;

// export const mockLocation = "1438 Broadway, Oakland, CA 94612, US";
// export const mockStreetNumber = "1438";
// export const mockStreetName = "Broadway";
// export const mockStreetAptNumber = "";
// export const mockCity = "Oakland";
// export const mockState = "CA";
// export const mockCountry = "US";
// export const mockZip = "94612";
// export const mockLatitude = 37.804905;
// export const mockLongitude = -122.270721;
// export const mockPhoneNumber = 1234567890;

export const mockLocation = "5 S Avondale Rd, Avondale Estates, GA 30002, USA";
export const mockStreetNumber = "5 S";
export const mockStreetName = "Avondaley Rd";
export const mockStreetAptNumber = "";
export const mockCity = "Avondale Estates";
export const mockState = "GA";
export const mockCountry = "US";
export const mockZip = "30002";
export const mockLatitude = 33.7752433;
export const mockLongitude = -84.2735426;
export const mockPhoneNumber = 1234567890;

export type TLocation = {
  longitude: number;
  latitude: number;
};

export const getGeocode = async (address: string): Promise<TLocation> => {
  const res = await get(`${productPlacementEndpointPath}/locations/geocode?address=${address}`);
  return await res.json();
};
