import { FC, ChangeEventHandler } from "react";
import "./RadioGroup.sass";
import { RadioButton } from "../RadioButton/RadioButton";

type TProps = {
  name: string;
  values: string[];
  labels: string[];
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const RadioGroup: FC<TProps> = ({ name, values, labels, onChange }) => {
  if (values.length !== labels.length) throw new Error("RadioGroup: values and labels do not have the same length");

  return (
    <fieldset className="RadioGroup">
      {values.map((value, idx) => {
        const label = labels[idx];
        return RadioButton({
          value: value,
          groupName: name,
          label: label,
          onChange: onChange,
        });
      })}
    </fieldset>
  );
};
