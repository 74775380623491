import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../app/store";
import "./Tracking.sass";
import { setShowOrderTracking } from "../productPlacementSlice";

export enum TrackingStatus {
  StoreConfirmed = "store_confirmed",
  PickupReady = "pickup_ready",
  Delivery3MinutesAway = "delivery_3_minutes_away",
  DriverArrived = "driver_arrived",
  OrderCanceled = "order_canceled",
  ChatInitiated = "chat_initiated",
}

export const trackingText = {
  [TrackingStatus.StoreConfirmed]: "Order confirmed by store",
  [TrackingStatus.PickupReady]: "Pick up is ready for delivery",
  [TrackingStatus.Delivery3MinutesAway]: "Delivery 3 minutes away from you",
  [TrackingStatus.DriverArrived]: "Delivery arrived",
  [TrackingStatus.OrderCanceled]: "Order has been cancelled",
  [TrackingStatus.ChatInitiated]: "Chat with the store started",
};

export const Tracking = () => {
  const dispatch = useDispatch();
  const showTrackingLink = useSelector<TRootState, boolean>((state) => state.productPlacement.showTracking);

  const [status, setStatus] = useState<string>("Wating for order confirmation");

  useEffect(() => {
    if (showTrackingLink) {
      let step = 0;
      const statuses = [
        TrackingStatus.StoreConfirmed,
        TrackingStatus.PickupReady,
        TrackingStatus.Delivery3MinutesAway,
        TrackingStatus.DriverArrived,
      ];

      const intervalId = setInterval(() => {
        if (!statuses[step]) {
          clearInterval(intervalId);
          dispatch(setShowOrderTracking(false));
          return;
        }

        setStatus(trackingText[statuses[step]]);
        step++;
      }, 10000);
    }
  }, [showTrackingLink, dispatch]);

  if (!showTrackingLink) {
    return null;
  }

  return (
    <div className="Tracking">
      <p className="status-text">{status}</p>
    </div>
  );
};
