import { productPlacementEndpointPath, post } from "./apiDetails";

export type TSelectedOptions = {
  option_id: string;
  quantity: number;
  marked_price?: number;
};

export type TOrderItem = {
  product_id: string;
  quantity?: number;
  product_marked_price?: number;
  selected_options?: TSelectedOptions[];
  notes: string;
};

export type TDropoffWindow = {
  start_time: number;
  end_time: number;
};

export type TOrder = {
  place_order: boolean;
  items: TOrderItem[];
  pickup: boolean;
  user_name: string;
  user_email: string;
  user_phone: number;
  user_id: string;
  tracking_link?: string;
  disable_sms?: boolean;
  include_final_quote?: boolean;
  charge_user?: boolean;
  payment_method_id?: string;
  scheduled_id?: string;
  driver_tip_cents?: number;
  pickup_tip_cents?: number;
  user_latitude?: number;
  user_longitude?: number;
  user_street_num?: string;
  user_street_name?: string;
  user_apt_num?: string;
  user_city?: string;
  user_state?: string;
  user_country?: string;
  user_zipcode?: string;
  user_dropoff_notes?: string;
  user_pickup_notes?: string;
  email_receipt_specifications?: object;
  sms_options?: object;
  favorited?: boolean;
  enable_substitution?: boolean;
  customer_order_id?: string;
  autofill_selected_options?: boolean;
  metadata?: object;
  dropoff_window?: TDropoffWindow;
};

export const createOrder = async (order: TOrder): Promise<any> => {
  const res = await post(`${productPlacementEndpointPath}/order`, order);
  return await res.json();
};
