import { FC } from "react";
import "./OrderPlacement.sass";
import { useDispatch, useSelector } from "react-redux";
import { MakingOrderStep, setProductPlacementStep } from "../productPlacementSlice";
import { TPlace } from "../types";
import { TRootState } from "../../../app/store";
import { MAX_PRODUCTS_SHOWN } from "../constants";

type TProps = {};

export const OrderPlacement: FC<TProps> = () => {
  const dispatch = useDispatch();
  const handleSetOrder = () => {
    dispatch(setProductPlacementStep(MakingOrderStep.Order));
  };
  const places = useSelector<TRootState, TPlace[]>((state) => state.productPlacement.places);
  const placePicked = useSelector<TRootState, string | undefined>((state) => state.productPlacement.placePicked);
  const chosenPlace = places.find((p) => p.place_id === placePicked);

  if (!chosenPlace) {
    return null;
  }

  return (
    <div className="OrderPlacement">
      <div className="order-placement-wrapper">
        <div className="dishes">
          <div className="popular-dishes">
            <h2 className="popular-dishes-header">Popular dishes</h2>
            <div className="dishes-row">
              {chosenPlace.products &&
                chosenPlace.products.slice(0, MAX_PRODUCTS_SHOWN).map((dish: any, index: number) => (
                  <div key={index} className="dish">
                    {dish.thumbnail_url && <img src={dish.thumbnail_url} alt={dish.name} className="dish-thumbnail" />}
                    <div className="dish-title">{dish.name}</div>
                    <div className="dish-details">
                      {dish.photos_count} Photos • {dish.reviews_count} Reviews
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="location-and-hours">
          <h2 className="popular-dishes-header">Location & Hours</h2>
          <div className="location-and-hours-container">
            <div>
              <iframe
                title="map-with-restaurant-location"
                src={`https://www.openstreetmap.org/export/embed.html?bbox=${chosenPlace.longitude - 0.01}%2C${
                  chosenPlace.latitude - 0.01
                }%2C${chosenPlace.longitude + 0.01}%2C${chosenPlace.latitude + 0.01}&layer=mapnik&marker=${
                  chosenPlace.latitude
                }%2C${chosenPlace.longitude}`}
              ></iframe>
              <div className="address">
                <p className="address-street">{chosenPlace.address.street_address}</p>
                <p className="address-city">{chosenPlace.address.locality}</p>
              </div>
            </div>
            <table className="opening-hours">
              <tbody>
                {Object.entries(chosenPlace.opening_hours).map(([day, hours], index: number) => (
                  <tr key={index}>
                    <td className="opening-hours-text opening-hours-day">{day}</td>
                    <td className="opening-hours-text">{hours}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button className="order-button" onClick={handleSetOrder}>
        <span className="order-button-text-big">Order Now</span>
        <span className="order-button-text-small">will get 10$ off</span>
      </button>
    </div>
  );
};
