import { FC } from "react";
import "./VideoGallery.sass";
import { useDispatch } from "react-redux";
import { setModalOpen } from "../productPlacementSlice";
import FallbackMovieImage from "../../../assets/images/no_movie.png";
import MexicoMockImage from "../../../assets/images/mexico_mock.jpg";

type TProps = {
  videos: any;
};

const VideoGallery: FC<TProps> = ({ videos }) => {
  const dispatch = useDispatch();

  return (
    <div className="gallery">
      <div className="thumbnails">
        {videos.map((video: any, index: number) => (
          <img
            key={index}
            src={
              video.poster_v ||
              ("Mexico One Plate at a Time Script" === video.title ? MexicoMockImage : false) ||
              FallbackMovieImage
            }
            alt={video.title}
            className="thumbnail"
            onClick={() => {
              dispatch(setModalOpen(true));
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default VideoGallery;
