import { Route, Routes } from "react-router-dom";
import { Login } from "../Login/Login";
import { ApplicationRoutes } from "../../app/routes";
import { PrototypesListPage } from "../../pages/PrototypesListPage";
import { ProductPlacementPage } from "../../pages/ProductPlacementPage";
import { FindingScenesPage } from "../../pages/FindingScenesPage";
import { FindingScenesUserFeedbackPage } from "../../pages/FindingScenesUserFeedbackPage";
import { FindingScenesUIPage } from "../../pages/FindingScenesUIPage";
import { PageNotFound } from "../../pages/PageNotFound";

export const AppRoutes = () => (
  <Routes>
    <Route path={ApplicationRoutes.main} element={<Login />} />
    <Route path={ApplicationRoutes.prototypesList} element={<PrototypesListPage />} />
    <Route path={ApplicationRoutes.productPlacement} element={<ProductPlacementPage />} />
    <Route path={ApplicationRoutes.findingScenes} element={<FindingScenesPage />} />
    <Route path={ApplicationRoutes.findingScenesUserFeedback} element={<FindingScenesUserFeedbackPage />} />
    <Route path={ApplicationRoutes.findingScenesUI} element={<FindingScenesUIPage />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);
