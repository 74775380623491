import { useEffect, useState } from "react";
import VideoGallery from "./VideoGallery/VideoGallery";
import VideoPlayerModal from "./VideoPlayer/VideoPlayerModal";
import { TVideo, getVideos } from "../../api/videos";
import { useDispatch } from "react-redux";
import { setModalOpen } from "./productPlacementSlice";
import "./ProductPlacement.sass";
import "./GoBackButton.sass";
import { Spinner } from "../../componentsShared/Spinner/Spinner";

export const ProductPlacement = () => {
  const dispatch = useDispatch();
  const [videos, setVideos] = useState<TVideo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [backgroundPoster, setBackgroundPoster] = useState("");
  const [mainVideo, setMainVideo] = useState<any>({});

  useEffect(() => {
    getVideos()
      .then((movies) => {
        const dearSanta = movies.filter((movie: any) => movie.id === "88888888")[0];
        setMainVideo(dearSanta);
        setBackgroundPoster(dearSanta.poster_h);
        setVideos(movies);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);

  if (loading) {
    return (
      <div className="ProductPlacement loader">
        <Spinner isOverlay />
      </div>
    );
  }

  return (
    <div
      className="ProductPlacement"
      style={{
        backgroundImage: `url(${backgroundPoster})`,
      }}
    >
      <div className="content-wrapper">
        <div className="title-display">{mainVideo.title}</div>
        <button
          className="watch-button"
          onClick={() => {
            dispatch(setModalOpen(true));
          }}
        >
          Watch
        </button>
        <div className="watch-now"></div>
        {videos.length > 0 && <VideoGallery videos={videos} />}
        <VideoPlayerModal selectedVideo={mainVideo} />
      </div>
    </div>
  );
};
