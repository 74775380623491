import { createSlice } from "@reduxjs/toolkit";
import { TOrderSummary, TPlace } from "./types";
import { TPaymentMethod } from "../../api/payment";
import { TPlacement } from "../../api/placements";

export enum MakingOrderStep {
  List = "list",
  Order = "order",
  Payment = "payment",
  Summary = "summary",
}

export type TProductPlacementState = {
  step: MakingOrderStep | null;
  isFullScreen: number;
  isModalOpen: boolean;
  places: TPlace[];
  placePicked: string | undefined;
  order: { [key: string]: number };
  paidOrderDetails: TOrderSummary | null;
  selectedPaymentMethod: TPaymentMethod | null;
  showTracking: boolean;
  currentPlacement: TPlacement | null;
};

export const initialState: TProductPlacementState = {
  step: null,
  isFullScreen: 0,
  isModalOpen: false,
  places: [],
  placePicked: undefined,
  selectedPaymentMethod: null,
  order: {},
  paidOrderDetails: null,
  showTracking: false,
  currentPlacement: null,
};

export const productPlacementSlice = createSlice({
  name: "productPlacement",
  initialState,
  reducers: {
    setProductPlacementStep: (state, action) => {
      state.step = action.payload;
    },
    resetMakingOrderSteps: (state) => {
      state.step = initialState.step;
    },
    setCurrentProductPlacement: (state, action) => {
      state.currentPlacement = action.payload;
    },
    setModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setFullscreen: (state) => {
      state.isFullScreen = state.isFullScreen + 1;
    },
    resetFullscreen: (state) => {
      state.isFullScreen = 0;
    },
    setPlaces: (state, action) => {
      state.places = action.payload;
    },
    pickPlace: (state, action) => {
      state.placePicked = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.selectedPaymentMethod = action.payload;
    },
    addOrderItem: (state, action) => {
      state.order = {
        ...state.order,
        ...action.payload,
      };
    },
    removeOrderItem: (state, action) => {
      delete state.order[action.payload];
    },
    resetOrder: (state) => {
      state.order = initialState.order;
    },
    setPaidOrderDetails: (state, action) => {
      state.paidOrderDetails = action.payload;
    },
    setShowOrderTracking: (state, action) => {
      state.showTracking = action.payload;
    },
  },
});

export const {
  setProductPlacementStep,
  resetMakingOrderSteps,
  setCurrentProductPlacement,
  setFullscreen,
  setModalOpen,
  resetFullscreen,
  setPlaces,
  pickPlace,
  setPaymentMethod,
  addOrderItem,
  removeOrderItem,
  resetOrder,
  setPaidOrderDetails,
  setShowOrderTracking,
} = productPlacementSlice.actions;

export default productPlacementSlice.reducer;
