import { FC } from "react";
import { useDispatch } from "react-redux";
import "./StepButton.sass";
import { MakingOrderStep, setProductPlacementStep } from "../productPlacementSlice";

type TProps = {
  text: string;
  step?: MakingOrderStep | null;
  handler?: Function;
};

export const StepButton: FC<TProps> = ({ text, step, handler }) => {
  const dispatch = useDispatch();

  return (
    <div className="StepButton">
      <p
        onClick={() => {
          if (handler) {
            handler();
            return;
          }
          dispatch(setProductPlacementStep(step));
        }}
      >
        {text}
      </p>
    </div>
  );
};
