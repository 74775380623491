import { TPlace } from "../components/ProductPlacement/types";
import { productPlacementEndpointPath, get } from "./apiDetails";

export const mockLocation = "852 W San Fernando St, San Jose, CA 95126, USA";

export enum SortPlaces {
  Cheapest = "cheapest",
  Fastest = "fastest",
  Rating = "rating",
  Distance = "distance",
  Relevance = "relevance",
}

export type TPlaceRequest = {
  longitude: number;
  latitude: number;
  keyword: string;
  sort?: SortPlaces;
  max?: number;
};

export const getPlaces = async ({ longitude, latitude, keyword }: TPlaceRequest): Promise<TPlace[]> => {
  const params = new URLSearchParams({
    longitude: String(longitude),
    latitude: String(latitude),
    keyword,
  }).toString();
  const res = await get(`${productPlacementEndpointPath}/places?${params}`);
  return await res.json();
};
