import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import "./Assistant.sass";
import { AssistantWrapper } from "./AssistantWrapper";
import { GoBack } from "./GoBack";
import { setAssistantActive, toggleSpoilers } from "../findingScenesSlice";
import { FC, RefObject, useState } from "react";
import { VoiceChat } from "../../../componentsShared/Voice/VoiceChat/VoiceChat";
import { searchMovie } from "../../../api/movieSearch";
import { TRootState } from "../../../app/store";
import { Toggle } from "../../../componentsShared/Inputs/Toggle/Toggle";
import { getUsername } from "../../../utils/token";
import { MockChat } from "./MockChat";

type TProps = {
  videoRef: RefObject<HTMLVideoElement>;
  videoId: string;
};

export const Assistant: FC<TProps> = ({ videoRef, videoId }) => {
  const dispatch = useDispatch();
  const sessionId = useSelector<TRootState, string>((state) => state.findingScenes.sessionId);
  const noSpoilers = useSelector<TRootState, boolean>((state) => state.findingScenes.noSpoilers);
  const [transcriptTransactionInProgress, setTranscriptTransactionInProgress] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [response, setResponse] = useState<string>("");
  const username = getUsername() || "";

  const submitQuery = () => {
    setTranscriptTransactionInProgress(true);
    const queryId = uuidv4();
    searchMovie(
      query,
      videoRef?.current?.currentTime || 0,
      noSpoilers,
      queryId,
      sessionId,
      username,
      videoId,
      videoRef?.current?.duration || 0,
      false
    )
      .then((response: any) => {
        if (response.graceful_error) {
          setResponse(response.graceful_error);
          return;
        }

        if (response.action.timestamp_ms || response.action.action_type === "show_part_action") {
          const video = videoRef?.current;
          const jumpTime = response.action.start_timestamp_ms || response.action.timestamp_ms;
          if (!video) return;

          setResponse("OK, let's jump to that moment.");

          setTimeout(() => {
            video.currentTime = jumpTime / 1000;
            video.play();
            cleanup();
          }, 2500);
          return;
        }

        const answer = response.action.text_summary || response.action.response || "";
        setResponse(answer);
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        setResponse("Error has occurred while trying to get response to your question. Try again later");
      })
      .finally(() => {
        setTranscriptTransactionInProgress(false);
      });
  };

  const cleanup = () => {
    dispatch(setAssistantActive(false));
    setQuery("");
    setResponse("");
  };

  return (
    <AssistantWrapper>
      <GoBack onClick={() => cleanup()} />
      <div className="Assistant">
        <div className="extras">
          <div className="buttons">
            <Toggle
              initChecked={!noSpoilers}
              textOn="spoilers on"
              textOff="spoilers off"
              handleChange={(checked) => dispatch(toggleSpoilers(!checked))}
            />
          </div>
        </div>
        <div className="example">
          <div className="you-can">You can ask</div>
          <div className="examples">"Who is Emma?" or "Jump to the moment when Fay meets Imhotep"</div>
        </div>
        <div className="chat">
          <div className="sender">
            <div className="label">Me:</div>
            <div className="message">
              <div className="mic">
                <VoiceChat
                  updateTranscript={setQuery}
                  onFinishedListening={submitQuery}
                  listenOnStart={false}
                  continousListening={false}
                  textToSpeak={response || null}
                  endingPhrases={["thanks", "thank you"]}
                />
              </div>
              {query}
            </div>
          </div>
          {(response || transcriptTransactionInProgress) && (
            <div className="receiver">
              <div className="label">Buddy:</div>
              <div className="message">
                {transcriptTransactionInProgress ? <span className="blinking">...</span> : response}
              </div>
            </div>
          )}
        </div>
      </div>
      <MockChat
        videoRef={videoRef}
        setQuery={setQuery}
        setTranscriptTransactionInProgress={setTranscriptTransactionInProgress}
        setResponse={setResponse}
        submitQuery={submitQuery}
        query={query}
      />
    </AssistantWrapper>
  );
};
