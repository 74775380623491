import { FC } from "react";
import "./Answer.sass";

type TProps = {
  text: string | null | undefined;
};

export const Answer: FC<TProps> = ({ text }) => {
  return <p className="Answer">{text || ""}</p>;
};
