import { videosEndpointPath, get, videosTestEndpointPath } from "./apiDetails";

export type TVideo = {
  id: string;
  origin_id: string;
  bundle_id: string;
  title: string;
  desc?: string;
  release_year?: string | null;
  length?: number;
  rating?: string;
  language?: string;
  genre?: string | null;
  media: string;
  poster_h: string;
  poster_v?: string;
  poster_h_medium?: string;
  poster_h_background?: string;
  source?: string;
};

export const getVideos = async (): Promise<TVideo[]> => {
  const res = await get(`${videosEndpointPath}/ideo`);
  return await res.json();
};

export const getVideosByIDs = async (videosIds: string[][]): Promise<TVideo[]> => {
  const res = await get(videosTestEndpointPath, videosIds);
  return await res.json();
};
