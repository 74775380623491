import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "../../app/store";
import { AppRoutes } from "./AppRoutes";
import "./App.sass";

export const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </Provider>
);
