import { FC } from "react";
import "./OrderPanelTitle.sass";

type TProps = {
  title: string;
};

export const OrderPanelTitle: FC<TProps> = ({ title }) => {
  return <div className="OrderPanelTitle">{title}</div>;
};
