import { FC, RefObject, useEffect } from "react";
import "./OrderPanel.sass";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../app/store";
import { MakingOrderStep, resetMakingOrderSteps } from "../productPlacementSlice";
import { Order } from "../Order/Order";
import { Payment } from "../Payment/Payment";
import { OrderSummary } from "../OrderSummary/OrderSummary";
import { OrderPlacementWrapper } from "./OrderPlacementWrapper";

type TProps = {
  videoRef?: RefObject<any>;
};

export const OrderPanelWrapper: FC<TProps> = ({ videoRef }) => {
  const dispatch = useDispatch();
  const orderStep = useSelector<TRootState, MakingOrderStep | null>((state) => state.productPlacement.step);

  useEffect(() => {
    return () => {
      dispatch(resetMakingOrderSteps());
    };
  }, [dispatch]);

  switch (orderStep) {
    case null:
      return null;
    case MakingOrderStep.Summary:
      return <OrderSummary videoRef={videoRef} />;
    case MakingOrderStep.Order:
      return <Order />;
    case MakingOrderStep.Payment:
      return <Payment />;
    case MakingOrderStep.List:
    default:
      return <OrderPlacementWrapper />;
  }
};
