import { useDispatch } from "react-redux";
import "./Logout.sass";
import { setIsAuthorized } from "../../components/Auth/authSlice";
import { ApplicationRoutes } from "../../app/routes";
import { removeToken } from "../../utils/token";

export const Logout = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setIsAuthorized(false));
    removeToken();
    window.location.href = ApplicationRoutes.main;
  };

  return (
    <p onClick={handleLogout} className="Logout">
      logout
    </p>
  );
};
