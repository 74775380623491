import { OrderPickerItem } from "./OrderPickerItem";
import "./OrderPicker.sass";
import { useSelector } from "react-redux";
import { TRootState } from "../../../app/store";
import { TPlace } from "../types";
import { TProduct } from "../../../api/products";

export const OrderPicker = () => {
  const places = useSelector<TRootState, TPlace[]>((state) => state.productPlacement.places);
  const placePicked = useSelector<TRootState, string | undefined>((state) => state.productPlacement.placePicked);

  if (!places || !placePicked) {
    return null;
  }

  return (
    <div className="OrderPicker">
      {places
        .find((p) => p.place_id === placePicked)
        ?.products?.map((product: TProduct) => (
          <OrderPickerItem key={`order-item-${product.product_id}}`} product={product} />
        ))}
    </div>
  );
};
