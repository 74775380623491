import { FC, ChangeEventHandler, useId } from "react";
import "./RadioButton.sass";

type TProps = {
  value: string;
  groupName: string;
  label: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const RadioButton: FC<TProps> = ({ value, groupName, label, onChange }) => {
  const id = useId();
  return (
    <div key={id}>
      <input type="radio" required id={id} value={value} name={groupName} onChange={onChange} className="RadioButton" />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
