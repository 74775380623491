import { ChangeEventHandler, FC } from "react";
import "./PasswordInput.sass";

type TProps = {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const PasswordInput: FC<TProps> = ({ value, onChange }) => {
  return <input type="password" placeholder="********" value={value} onChange={onChange} className="PasswordInput" />;
};
