import { Layout } from "../componentsShared/Layout/Layout";
import { AuthorizedOnly } from "../components/Auth/AuthorizedOnly";
import { BackButton } from "../componentsShared/Buttons/BackButton/BackButton";

export const PageNotFound = () => {
  return (
    <AuthorizedOnly>
      <Layout>
        <BackButton />
        <div style={{ display: "flex", justifyContent: "center", margin: "10vw" }}>
          <h1 style={{ color: "white" }}>Page not found</h1>
        </div>
      </Layout>
    </AuthorizedOnly>
  );
};
