import classNames from "classnames";
import "./Spinner.sass";
import { FC } from "react";

type TProps = {
  isOverlay?: boolean;
  description?: string;
};

export const Spinner: FC<TProps> = ({ isOverlay = false, description = "" }) => {
  return (
    <div className={classNames("Spinner", { overlay: isOverlay })}>
      <span className="spinner-circle" />
      <div className="description">{description}</div>
    </div>
  );
};
