import { FC, useEffect, useState } from "react";
import "./Order.sass";
import { OrderPicker } from "./OrderPicker";
import { MakingOrderStep, setProductPlacementStep } from "../productPlacementSlice";
import { StepButton } from "./StepButton";
import { useDispatch, useSelector } from "react-redux";
import { ErrorNotification } from "../../../componentsShared/Notification/ErrorNotification/ErrorNotification";
import { TRootState } from "../../../app/store";

type TProps = {};

export const Order: FC<TProps> = () => {
  const dispatch = useDispatch();
  const order = useSelector<TRootState, { [key: string]: number }>((state) => state.productPlacement.order);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const isAnyOrderChosen = Object.keys(order).length;

  useEffect(() => {
    setErrorMessage("");
  }, [isAnyOrderChosen]);

  const handleProceedToPayment = () => {
    if (isAnyOrderChosen) {
      dispatch(setProductPlacementStep(MakingOrderStep.Payment));
    } else {
      setErrorMessage("Please choose products before going to payment");
    }
  };

  return (
    <div className="Order" onClick={(e) => e.stopPropagation()}>
      <div className="order-picker">
        <OrderPicker />
      </div>
      {errorMessage && (
        <div className="error-message">
          <ErrorNotification text={errorMessage} />
        </div>
      )}
      <StepButton text="Order" handler={handleProceedToPayment} />
    </div>
  );
};
