import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { resetFullscreen, setFullscreen, setModalOpen } from "../productPlacementSlice";
import { TRootState } from "../../../app/store";
import { useEffect } from "react";

export const ModalControls = () => {
  const dispatch = useDispatch();
  const isFullscreen = useSelector<TRootState, number>((state) => state.productPlacement.isFullScreen);

  const handleFullscreen = () => {
    dispatch(setFullscreen());
  };

  useEffect(() => {
    return () => {
      dispatch(resetFullscreen());
    };
  }, [dispatch]);

  return (
    <div className="ModalControls">
      <FontAwesomeIcon onClick={() => dispatch(setModalOpen(false))} className="close-button" icon={faXmark} />
      <FontAwesomeIcon
        className={`fullscreen-button ${isFullscreen ? "fullscreen" : ""}`}
        onClick={handleFullscreen}
        icon={faExpand}
      />
    </div>
  );
};
