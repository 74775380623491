import { createSlice } from "@reduxjs/toolkit";

export type TAuthState = {
  isAuthorized: boolean;
};

export const authInitialState: TAuthState = {
  isAuthorized: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
  },
});

export const { setIsAuthorized } = authSlice.actions;

export default authSlice.reducer;
