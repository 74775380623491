import { ProductPlacement } from "../components/ProductPlacement/ProductPlacement";
import { Layout } from "../componentsShared/Layout/Layout";
import { AuthorizedOnly } from "../components/Auth/AuthorizedOnly";
import { BackButton } from "../componentsShared/Buttons/BackButton/BackButton";

export const ProductPlacementPage = () => {
  return (
    <AuthorizedOnly>
      <Layout>
        <BackButton />
        <ProductPlacement />
      </Layout>
    </AuthorizedOnly>
  );
};
