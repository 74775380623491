import { ChangeEvent, FC, useState } from "react";
import "./Toggle.sass";
import classNames from "classnames";

type TProps = {
  handleChange: (isChecked: boolean) => void;
  className?: string;
  textOn?: string;
  textOff?: string;
  initChecked?: boolean;
};

export const Toggle: FC<TProps> = ({ handleChange, className, textOn, textOff, initChecked = false }) => {
  const [checked, setChecked] = useState<boolean>(initChecked);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.currentTarget.checked;
    setChecked(isChecked);
    handleChange(isChecked);
  };

  return (
    <div className="Toggle">
      <label className="switch">
        <input type="checkbox" onChange={(e) => onChange(e)} checked={checked} />
        <span className="slider round" />
      </label>
      <p className={classNames(className, "text", { on: checked })}>{checked ? textOn : textOff}</p>
    </div>
  );
};
