import { useState, useEffect, FC } from "react";
import Modal from "react-modal";
import VideoPlayer from "./VideoPlayer";
import { TPlacement, getPlacements } from "../../../api/placements";
import "./VideoPlayerModal.sass";
import { ModalControls } from "./ModalControls";
import { useDispatch, useSelector } from "react-redux";
import { setModalOpen, setShowOrderTracking } from "../productPlacementSlice";
import { TRootState } from "../../../app/store";
import "./ModalControls.sass";
import { Tracking } from "../Tracking/Tracking";

Modal.setAppElement("#root");

type TProps = {
  selectedVideo: any;
};

const VideoPlayerModal: FC<TProps> = ({ selectedVideo }) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector<TRootState, boolean>((state) => state.productPlacement.isModalOpen);
  const [placements, setPlacements] = useState<TPlacement[]>([]);
  const handleCloseModal = () => {
    dispatch(setModalOpen(false));
  };

  useEffect(() => {
    if (selectedVideo?.id) {
      getPlacements(selectedVideo.id)
        .then((resposne: TPlacement[]) => {
          setPlacements(resposne);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  }, [isModalOpen, selectedVideo]);

  useEffect(() => {
    return () => {
      dispatch(setShowOrderTracking(false));
    };
  }, [dispatch]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      className="VideoPlayerModal"
      contentLabel="Video Player"
    >
      {selectedVideo && placements && <VideoPlayer url={selectedVideo.media} placements={placements} />}
      <ModalControls />
      <Tracking />
    </Modal>
  );
};

export default VideoPlayerModal;
