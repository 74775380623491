import { FindingScenes } from "../components/FindingScenes/FindingScenes";
import { Layout } from "../componentsShared/Layout/Layout";
import { AuthorizedOnly } from "../components/Auth/AuthorizedOnly";
import { BackButton } from "../componentsShared/Buttons/BackButton/BackButton";

export const FindingScenesUIPage = () => {
  return (
    <AuthorizedOnly>
      <Layout>
        <BackButton />
        <FindingScenes enableFeedback={false} ui />
      </Layout>
    </AuthorizedOnly>
  );
};
