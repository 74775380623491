import { ChangeEventHandler, FC } from "react";
import "./TextInput.sass";

type TProps = {
  value: string;
  placeholder: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  required: boolean;
};

export const TextInput: FC<TProps> = ({ value, placeholder, onChange, required }) => {
  return (
    <input
      type="text"
      required={required}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="TextInput"
    />
  );
};
