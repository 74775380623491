import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import bannerTitle from "../../../assets/images/banner_title.png";
import { toggleFullscreen } from "../../../utils/fullscreen";
import { OrderPanel } from "../OrderPanel/OrderPanel";
import { TRootState } from "../../../app/store";
import "./VideoPlayer.sass";
import { GoBackButton } from "../GoBackButton";
import { MakingOrderStep, setCurrentProductPlacement, setProductPlacementStep } from "../productPlacementSlice";
import { TPlacement } from "../../../api/placements";
import Hls from "hls.js";
import { Banner } from "../Banner";

type TProps = {
  url: string;
  placements: TPlacement[];
};

const VideoPlayer: FC<TProps> = ({ url, placements }) => {
  const dispatch = useDispatch();
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef(null);
  const [isVideoReady, setIsVideoReady] = useState<boolean>(false);
  const isFullscreen = useSelector<TRootState, number>((state) => state.productPlacement.isFullScreen);
  const orderStep = useSelector<TRootState, MakingOrderStep | null>((state) => state.productPlacement.step);
  const showTracking = useSelector<TRootState, boolean>((state) => state.productPlacement.showTracking);
  const currentPlacement = useSelector<TRootState, TPlacement | null>(
    (state) => state.productPlacement.currentPlacement
  );

  const [hideControls, setHideControls] = useState(false);

  const hideRestaurantPanel = () => {
    dispatch(setProductPlacementStep(null));
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    const video: any = videoRef.current;
    const keyNumber = parseInt(event.key, 10);

    if (keyNumber === 0) {
      video.currentTime = 3877;
      return;
    }

    if (event.key === "q") {
      dispatch(setProductPlacementStep(MakingOrderStep.List));
    }

    if (event.key === "h") {
      setHideControls(!hideControls);
      return;
    }

    if (!isNaN(keyNumber) && keyNumber >= 1 && keyNumber <= placements.length) {
      try {
        video.currentTime = placements[keyNumber - 1].start_time_sec - 3; // 3s to see when banner comes up
      } catch {
        console.log("missed e", keyNumber, placements.length);
      }
    }
  };

  const checkPlacement = () => {
    const video: any = videoRef.current;

    if (!placements || !video) {
      return;
    }

    const currentTime = video.currentTime;
    const placementFound = placements.find(
      (placement) => placement.start_time_sec <= currentTime && placement.end_time_sec >= currentTime
    );

    if (placementFound?.start_time_sec) {
      dispatch(setCurrentProductPlacement(placementFound));
    }
  };

  useEffect(() => {
    if (isFullscreen) {
      toggleFullscreen(containerRef);
    }
  }, [isFullscreen]);

  useEffect(() => {
    if (orderStep === MakingOrderStep.List && currentPlacement) {
      if (videoRef.current) videoRef.current.pause();
      dispatch(setCurrentProductPlacement(currentPlacement));
    } else if (orderStep === MakingOrderStep.List && !currentPlacement) {
      dispatch(setProductPlacementStep(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStep, currentPlacement]);

  useEffect(() => {
    dispatch(setCurrentProductPlacement(null));
    const video: any = videoRef.current;

    if (videoRef.current) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(video);
    }

    return () => {
      video.removeEventListener("timeupdate", checkPlacement);
      if (orderStep) {
        hideRestaurantPanel();
      }

      if (video) {
        video.src = "";
        video.removeAttribute("src");
      }
      setIsVideoReady(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!videoRef.current) return;
    const video: any = videoRef.current;

    if (isVideoReady) {
      video.addEventListener("timeupdate", checkPlacement);
      document.addEventListener("keydown", handleKeyPress);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVideoReady]);

  const showPlacementBanner = !showTracking && currentPlacement;

  const onVideoReady = () => {
    setIsVideoReady(true);
  };

  return (
    <div className="VideoPlayer" ref={containerRef}>
      {showPlacementBanner && (
        <Banner bannerTitle={bannerTitle} currentPlacement={currentPlacement} videoRef={videoRef} />
      )}
      <video ref={videoRef} controls={!hideControls} className="video-player" onLoadedData={onVideoReady} autoPlay />
      {orderStep && (
        <>
          <GoBackButton />
          <OrderPanel
            videoRef={videoRef}
            onClick={() => {
              hideRestaurantPanel();
              videoRef?.current?.play();
            }}
          />
        </>
      )}
    </div>
  );
};

export default VideoPlayer;
