import { FC, MouseEventHandler } from "react";
import "./VideoGallery.sass";
import FallbackMovieImage from "../../../assets/images/no_movie.png";
import { ideoS3BucketUrl } from "../../../api/apiDetails";

type TProps = {
  video: any;
  callback: Function;
};

export const Thumbnail: FC<TProps> = ({ video, callback }) => {
  let poster =
    video.poster_v ||
    video.poster_v_small ||
    video.poster_v_medium ||
    video.poster_video ||
    video.poster_h ||
    video.poster_h_large ||
    video.poster_h_medium ||
    video.poster_h_raw ||
    video.poster_h_small ||
    video.poster_h_small2 ||
    video.poster_video_large ||
    video.poster_video_medium ||
    video.poster_video_raw ||
    video.poster_video_small ||
    "";

  poster = `${ideoS3BucketUrl}${poster}`;

  return (
    <div className="thumbnail">
      <img
        src={poster || FallbackMovieImage}
        alt={video.title}
        onClick={callback as MouseEventHandler<HTMLImageElement>}
      />
    </div>
  );
};
