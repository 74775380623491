import React, { FC } from "react";
import banner from "../../assets/images/banner.png";
import { TPlacement } from "../../api/placements";

type TProps = {
  bannerTitle: string;
  videoRef: React.RefObject<HTMLVideoElement>;
  currentPlacement: TPlacement;
};

export const Banner: FC<TProps> = ({ bannerTitle, videoRef, currentPlacement }) => {
  return (
    <div className="product-placement">
      <img src={bannerTitle} alt="Title" className="title-image" />
      <img src={banner} alt="Clickable" className="banner-image" onClick={() => videoRef.current?.pause()} />
      <div className="product-placement-text">
        <em>{currentPlacement?.message || ""}</em>&nbsp;
        <strong className="strong-text">Press [Q] to start ordering now!</strong>
      </div>
    </div>
  );
};
