import { useDispatch, useSelector } from "react-redux";
import "./Payment.sass";
import { TRootState } from "../../../app/store";
import { createOrder } from "../../../api/order";
import { getUsername } from "../../../utils/token";
import { useEffect, useState } from "react";
import { Spinner } from "../../../componentsShared/Spinner/Spinner";
import { getCreateOrderPayload } from "../helpers";
import { ErrorNotification } from "../../../componentsShared/Notification/ErrorNotification/ErrorNotification";
import {
  MakingOrderStep,
  setPaidOrderDetails,
  setPaymentMethod,
  setProductPlacementStep,
} from "../productPlacementSlice";
import { TPaymentMethod, createPaymentMethod, getPaymentMethods } from "../../../api/payment";
import { getMockCardData, getMockEMail } from "../../../utils/mocks";

export const Payment = () => {
  const dispatch = useDispatch();
  const orders = useSelector<TRootState, { [key: string]: number }>((state) => state.productPlacement.order);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleCreateOrder = (paymentMethod: TPaymentMethod) => {
    setLoading(true);

    const userName = getUsername() || "";

    getCreateOrderPayload(orders, paymentMethod?.payment_method_id || "", userName).then((payload) => {
      createOrder(payload).then((order_details) => {
        if (order_details?.error || order_details?.detail) {
          setErrorMessage(order_details?.error);
        } else {
          const order_id = order_details?.order_id;
          localStorage.setItem("order_id", order_id); // TODO: check for order and show orders not finalized otherwise clear
          dispatch(setPaidOrderDetails(order_details));
          dispatch(setProductPlacementStep(MakingOrderStep.Summary));
        }

        setLoading(false);
      });
    });
  };

  useEffect(() => {
    const email = getMockEMail();
    setLoading(true);

    getPaymentMethods(email).then((paymentMethods: TPaymentMethod[]) => {
      if (!paymentMethods.length) {
        createPaymentMethod(getMockCardData(), getUsername() || "", email).then((paymentMethod) => {
          dispatch(setPaymentMethod(paymentMethod));
          handleCreateOrder(paymentMethod);
        });
      } else {
        dispatch(setPaymentMethod(paymentMethods[0]));
        handleCreateOrder(paymentMethods[0]);
      }
    });
    /* eslint-disable */
  }, [dispatch]);

  return (
    <div className="Payment" onClick={(e) => e.stopPropagation()}>
      {loading ? (
        <Spinner isOverlay description="preparing order for you..." />
      ) : (
        <>
          {errorMessage && (
            <div className="error-container">
              <ErrorNotification text={errorMessage} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
