import { FC, RefObject, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay, faReply, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import BuddyImg from "../../../assets/images/buddy.png";
import "./PauseNavigationOverlay.sass";
import { TRootState } from "../../../app/store";
import { setAssistantActive } from "../findingScenesSlice";
import { Assistant } from "../Assistant/Assistant";

type TProps = {
  videoRef: RefObject<HTMLVideoElement>;
  title: string;
  videoId: string;
};

export const PauseNavigationOverlay: FC<TProps> = ({ videoRef, title, videoId }) => {
  const dispatch = useDispatch();
  const [showPauseNavigation, setShowPauseNavigation] = useState<boolean>(false);
  const isPaused = videoRef.current?.paused;
  const isAssistantActive = useSelector<TRootState, boolean>((state) => state.findingScenes.isAssistantActive);

  useEffect(() => {
    dispatch(setAssistantActive(false));

    return () => {
      dispatch(setAssistantActive(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.addEventListener("pause", () => {
      setShowPauseNavigation(true);
    });
    videoRef.current.addEventListener("play", () => {
      setShowPauseNavigation(false);
    });
  }, [videoRef]);

  const handleAssistant = () => {
    dispatch(setAssistantActive(true));
  };

  const handlePause = () => {
    if (isPaused) {
      setShowPauseNavigation(false);
      videoRef.current?.play();
    } else {
      setShowPauseNavigation(true);
      videoRef.current?.pause();
    }
  };

  if (!showPauseNavigation) {
    return null;
  }

  if (isAssistantActive) {
    return <Assistant videoRef={videoRef} videoId={videoId} />;
  }

  return (
    <div className="PauseNavigationOverlay">
      <div className="title-container">
        <div className="title">{title}</div>
        <div className="pg-icon">PG 13</div>
      </div>
      <div className="pause-panel">
        <div className="video-control">
          <div className="go-back" onClick={handlePause}>
            <FontAwesomeIcon icon={faReply} />
          </div>
          <div className="pause-button" onClick={handlePause}>
            {isPaused ? (
              <>
                <FontAwesomeIcon icon={faPlay} />
                <p>Play</p>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faPause} />
                <p>Pause</p>
              </>
            )}
          </div>
          <div className="cc">
            <p>CC</p>
          </div>
          <div className="switch">
            {true ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />}
          </div>
        </div>
        <div className="assistant">
          <div className="intro">
            <p className="pre-subtitle">Buddy:</p>
            <p className="introduction">Come on! Let's chat.</p>
          </div>
          <div className="chat-bot-image" onClick={handleAssistant}>
            <img src={BuddyImg} alt="Your buddy" />
            <p className="name">Buddy</p>
          </div>
        </div>
      </div>
    </div>
  );
};
