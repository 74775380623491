import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export type TFindingScenesState = {
  isAssistantActive: boolean;
  sessionId: string;
  noSpoilers: boolean;
};

export const initialState: TFindingScenesState = {
  isAssistantActive: false,
  sessionId: uuidv4(),
  noSpoilers: true,
};

export const findingScenesSlice = createSlice({
  name: "productPlacement",
  initialState,
  reducers: {
    setAssistantActive: (state, action) => {
      state.isAssistantActive = action.payload;
    },
    toggleSpoilers: (state, action) => {
      state.noSpoilers = action.payload;
    },
  },
});

export const { setAssistantActive, toggleSpoilers } = findingScenesSlice.actions;

export default findingScenesSlice.reducer;
