import { FC, useEffect, useState } from "react";
import "./SubmitButton.sass";
import { Spinner } from "../../Spinner/Spinner";

type TProps = {
  text: string;
  isSubmitting?: boolean;
  disabled: boolean;
};

export const SubmitButton: FC<TProps> = ({ text, isSubmitting = false, disabled = false }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(isSubmitting);
  }, [isSubmitting]);

  return (
    <button type="submit" className="SubmitButton" disabled={disabled}>
      {isLoading ? <Spinner /> : text}
    </button>
  );
};
