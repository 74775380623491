import { getUsername } from "../utils/token";
import { productPlacementEndpointPath, get, post } from "./apiDetails";

export type TCardPaymentMethod = {
  card_number: number;
  expiration_year: number;
  expiration_month: number;
  cvc: string;
};

export type TPaymentMethod = {
  payment_method_id: string;
  last_four_digits: string;
};

export const getPaymentMethods = async (user_email: string): Promise<TPaymentMethod[]> => {
  const res = await get(
    `${productPlacementEndpointPath}/payment_methods?user_name=${getUsername()}&user_email=${user_email}`
  );
  return await res.json();
};

export const createPaymentMethod = async (
  paymentMethod: TCardPaymentMethod,
  user_name: string,
  user_email: string
): Promise<TPaymentMethod> => {
  const res = await post(`${productPlacementEndpointPath}/payment_methods`, {
    user_name,
    user_email,
    payment_method_object: paymentMethod,
  });
  return await res.json();
};
