import { FC, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "./FindingScenes.sass";
import VideoGallery from "./VideoGallery/VideoGallery";
import VideoPlayerModal from "./VideoPlayer/VideoPlayerModal";
import { TVideo, getVideosByIDs } from "../../api/videos";
import { v4 as uuidv4 } from "uuid";
import { featuredMovieId, moviesIds } from "../../utils/mocks";
import { ideoS3BucketUrl } from "../../api/apiDetails";
import { Spinner } from "../../componentsShared/Spinner/Spinner";

type TProps = {
  enableFeedback: boolean;
  ui: boolean;
};

export const FindingScenes: FC<TProps> = ({ enableFeedback, ui }) => {
  const [videos, setVideos] = useState<TVideo[]>([]);
  const [backgroundPoster, setBackgroundPoster] = useState("");
  const [mainVideo, setMainVideo] = useState<any>({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [sessionId] = useState<string>(uuidv4());

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    getVideosByIDs(moviesIds.map((movie_id) => ["ids", movie_id]))
      .then((movies_list) => {
        const featuredMovie = movies_list.filter((movie: any) => movie.origin_id === featuredMovieId)[0];
        if (featuredMovie) {
          setMainVideo(featuredMovie);
          setBackgroundPoster(featuredMovie.poster_h_medium || "");
        }
        setVideos([...movies_list]);
      })
      .catch((error) => console.error("Error fetching data: ", error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!mainVideo) {
    return <Spinner />;
  }

  return (
    <div
      className="FindingScenes"
      style={{
        backgroundImage: `url("${ideoS3BucketUrl}${backgroundPoster}")`,
      }}
    >
      <ToastContainer />
      <div className="content-wrapper">
        <div className="title-display">{mainVideo.title}</div>
        <button
          className="watch-button"
          onClick={() => {
            openModal();
          }}
        >
          Watch
        </button>
        <div className="watch-now"></div>
        {videos.length && (
          <VideoGallery videos={videos} enableFeedback={enableFeedback} sessionId={sessionId} ui={ui} />
        )}
        <VideoPlayerModal
          isOpen={modalIsOpen}
          closeModal={closeModal}
          selectedVideo={mainVideo}
          enableFeedback={enableFeedback}
          sessionId={sessionId}
          ui={ui}
        />
      </div>
    </div>
  );
};
