import { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./VoiceChat.sass";
import { faMicrophone, faMicrophoneSlash, faVolumeHigh, faSpinner } from "@fortawesome/free-solid-svg-icons";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
// @ts-ignore
import { TtsEngine } from "ttsreader";

type TProps = {
  updateTranscript: Function;
  onFinishedListening: Function;
  listenOnStart: boolean;
  continousListening: boolean;
  textToSpeak: string | null;
  endingPhrases: string[];
};

export const VoiceChat: FC<TProps> = ({
  updateTranscript,
  onFinishedListening,
  listenOnStart,
  continousListening,
  textToSpeak,
  endingPhrases,
}) => {
  const options = {
    language: "en-US",
  };
  const {
    transcript,
    finalTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();
  const canListen = browserSupportsSpeechRecognition && isMicrophoneAvailable;

  const [reading, setReading] = useState<boolean>(false);
  let tts = TtsEngine;
  useEffect(() => {
    tts.init({
      onInit: (voices: any) => {
        console.log("TTS Init");
        tts.setVoiceByUri("Google US English");
        // tts.setBestMatchingVoice(null, null, "en-US")
      },

      // onStart: () => {
      //     onStart();
      // },

      onDone: () => {
        onTTSEnd();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listening) {
      updateTranscript(transcript);
    }
    if (!listening && finalTranscript.length > 0) {
      if (isEndingPhrase(finalTranscript)) {
        console.log("ending phrase; stop listening");
      } else {
        onFinishedListening();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript, listening, finalTranscript]);

  useEffect(() => {
    if (listenOnStart && canListen) {
      toggleSpeechRecognition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSpeechRecognition = () => {
    if (!listening) {
      SpeechRecognition.startListening(options);
    } else {
      SpeechRecognition.stopListening();
      resetTranscript();
    }
  };

  function speak(text: string | null) {
    console.log("TTS:", text);
    if (text) {
      setReading(true);
      tts.speakOut(text);
    }
  }

  function stopTTS() {
    tts.stop();
    setReading(false);
  }

  function onTTSEnd() {
    console.log("onTTSEnd");
    setReading(false);
    if (continousListening) {
      console.log("Continous listening mode; starting to listen again...");
      SpeechRecognition.startListening(options);
    }
  }

  function isEndingPhrase(transcript: string): boolean {
    transcript = transcript.trim().toLowerCase();
    return endingPhrases.some((p) => p.includes(transcript));
  }

  useEffect(() => {
    speak(textToSpeak);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textToSpeak]);

  useEffect(() => {
    return () => {
      stopTTS();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (reading) {
    return (
      <div className="VoiceChatButton">
        <FontAwesomeIcon onClick={() => stopTTS()} icon={faVolumeHigh} beat={true} />
      </div>
    );
  } else if (canListen) {
    return (
      <div className="VoiceChatButton">
        <FontAwesomeIcon
          onClick={() => toggleSpeechRecognition()}
          icon={listening ? faSpinner : faMicrophone}
          spin={listening ? true : false}
        />
      </div>
    );
  } else {
    return (
      <div
        className="VoiceChatButtonDisabled"
        title="Microphone disabled or your browser doesn't support speech recognition"
      >
        <FontAwesomeIcon icon={faMicrophoneSlash} />
      </div>
    );
  }
};
