import {
  // getGeocode,
  mockCity,
  mockCountry,
  mockLatitude,
  mockLongitude,
  mockState,
  mockStreetAptNumber,
  mockStreetName,
  mockStreetNumber,
  mockZip,
} from "../../api/locations";
import { TSelectedOptions } from "../../api/order";
import { TPlacement } from "../../api/placements";
import { getPlaces } from "../../api/places";
import {
  getProducts,
  getProductsDetails,
  // mockLocation,
} from "../../api/products";
import { getMockEMail } from "../../utils/mocks";
import { TPlace } from "./types";

export const getCustomizations = (customizations: any[]): TSelectedOptions[] => {
  let chosenOptions: TSelectedOptions[] = [];

  customizations.forEach(({ options }) => {
    if (options[0].customizations.length) {
      const nestedOptions = getCustomizations(options[0].customizations);
      chosenOptions = [...chosenOptions, ...nestedOptions];
    }

    chosenOptions.push({
      option_id: options[0].option_id,
      quantity: 1,
    });
  });
  return chosenOptions;
};

export const getPlacesProducts = async (currentPlacement: TPlacement): Promise<TPlace[]> => {
  // const geocode = await getGeocode(mockLocation); // TODO: check geocode issues with address

  let places: TPlace[] = await getPlaces({
    // longitude: geocode.longitude,
    // latitude: geocode.latitude,
    longitude: mockLongitude,
    latitude: mockLatitude,
    keyword: currentPlacement.keyword,
  });

  for (let { place_id } of places) {
    // TODO: make request in paralel
    const products = await getProducts({
      place_id,
      longitude: mockLongitude,
      latitude: mockLatitude,
      street_num: mockStreetNumber,
      street_name: mockStreetName,
      locality: mockCity,
      region: mockState,
      postal_code: mockZip,
      country: mockCountry,
    });

    places = places.map((place) => {
      if (place.place_id === place_id) {
        return {
          ...place,
          products,
        };
      }
      return place;
    });
  }

  return places;
};

export const getCreateOrderPayload = async (
  products: { [key: string]: number },
  selectedPaymentMethodId: string,
  userName: string
) => {
  const productsList = Object.entries(products);
  const productsIds = productsList.map(([productId]) => productId);

  let productsDetails = await getProductsDetails(productsIds);
  productsDetails = productsDetails.map(({ product_id, customizations }: any) => {
    return {
      product_id,
      quantity: products[product_id],
      product_marked_price: 1,
      notes: "",
      selected_options: getCustomizations(customizations),
    };
  });

  return {
    place_order: true,
    payment_method_id: selectedPaymentMethodId,
    charge_user: true,
    items: productsDetails,
    pickup: false,
    user_name: userName,
    user_email: getMockEMail(),
    user_phone: 1234567890,
    user_id: userName, // TODO: user id for now
    user_latitude: mockLatitude,
    user_longitude: mockLongitude,
    user_street_num: mockStreetNumber,
    user_street_name: mockStreetName,
    user_apt_num: mockStreetAptNumber,
    user_city: mockCity,
    user_state: mockState,
    user_country: mockCountry,
    user_zipcode: mockZip,
    user_dropoff_notes: "no notes",
    include_final_quote: true,
  };
};
