import { FC, RefObject } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepButton } from "../Order/StepButton";
import { OrderPanelTitle } from "../OrderPanel/OrderPanelTitle";
import "./OrderSummary.sass";
import { setProductPlacementStep, setShowOrderTracking } from "../productPlacementSlice";
import { TRootState } from "../../../app/store";
import { TPaymentMethod } from "../../../api/payment";
import { TOrderSummary, TPlace } from "../types";
import { getUsername } from "../../../utils/token";
import {
  mockCity,
  mockCountry,
  mockPhoneNumber,
  mockState,
  mockStreetName,
  mockStreetNumber,
  mockZip,
} from "../../../api/locations";

type TProps = {
  videoRef: RefObject<any> | undefined;
};

export const OrderSummary: FC<TProps> = ({ videoRef }) => {
  const dispatch = useDispatch();
  const orderSummary = useSelector<TRootState, TOrderSummary | null>(
    (state) => state.productPlacement.paidOrderDetails
  );
  const selectedPaymentMethod = useSelector<TRootState, TPaymentMethod | null>(
    (state) => state.productPlacement.selectedPaymentMethod
  );
  const places = useSelector<TRootState, TPlace[]>((state) => state.productPlacement.places);
  const placePickedId = useSelector<TRootState, string | undefined>((state) => state.productPlacement.placePicked);
  const placePicked = places.find((p) => p.place_id === placePickedId);

  const handleContinue = () => {
    dispatch(setProductPlacementStep(null));
    videoRef?.current.play();

    dispatch(setShowOrderTracking(true));

    // TODO: here handle Notifications
  };

  // @ts-ignore
  const now = new Date().toGMTString();

  return (
    <div className="OrderSummary" onClick={(e) => e.stopPropagation()}>
      <OrderPanelTitle title="Thanks for your order" />
      <div className="summary-wrapper">
        <div className="order-picture-container">
          <img src={placePicked?.thumbnail_url} alt="chosen place" />
        </div>
        <div className="details-container">
          <div className="billing-title">{orderSummary?.final_quote.store}</div>
          <table>
            <tbody>
              <tr>
                <td>Ordered on</td>
                <td>{now}</td>
              </tr>
              <tr>
                <td>Ordered Number</td>
                <td className="order-number">{orderSummary?.order_id}</td>
              </tr>
              <tr>
                <td>Payment</td>
                <td>**** **** **** {selectedPaymentMethod?.last_four_digits}</td>
              </tr>
              <tr>
                <td className="delivery-to">Delivery to</td>
                <td>
                  <p>{getUsername()}</p>
                  <p>
                    {mockStreetNumber} {mockStreetName}
                  </p>
                  <p>
                    {mockCity} {mockState} {mockZip}
                  </p>
                  <p>{mockCountry}</p>
                </td>
              </tr>
              <tr>
                <td>Contact Number</td>
                <td>
                  <p>{mockPhoneNumber}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="order-details-container">
          <div className="billing-title">ORDER SUMMARY</div>
          <table>
            <tbody>
              {orderSummary?.final_quote.items.map((item) => (
                <tr key={`item-${item.name}`}>
                  <td>
                    {item.quantity} x {item.name}
                  </td>
                  <td>${+item.base_price / 100}</td>
                </tr>
              ))}
              <tr className="pre-summary-part">
                <td>Tip</td>
                <td>${orderSummary?.final_quote.tip}</td>
              </tr>
              <tr className="summary-part">
                <td>Total</td>
                <td>${+(orderSummary?.final_quote.total_with_tip || 0) / 100}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <StepButton text="OK" handler={handleContinue} />
    </div>
  );
};
