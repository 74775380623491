import { productPlacementEndpointPath, get, post } from "./apiDetails";

// export const mockLocation = "852 W San Fernando St, San Jose, CA 95126, US";
export const mockLocation = "188 King Street St, San Francisco, CA, 94107, US";

export enum SortPlaces {
  Cheapest = "cheapest",
  Fastest = "fastest",
  Rating = "rating",
  Distance = "distance",
  Relevance = "relevance",
}

export type TProductsRequest = {
  place_id: string;
  longitude: number;
  latitude: number;
  street_num: string;
  street_name: string;
  locality: string;
  region: string;
  postal_code: string;
  country: string;
};

export type TProduct = {
  product_id: string;
  thumbnail_url: string;
  name: string;
  quantity_available: number | null;
};

export const getProducts = async (productsRequest: TProductsRequest): Promise<TProduct[]> => {
  const params = new URLSearchParams(
    Object.entries(productsRequest).map(([key, value]) => [key, String(value)])
  ).toString();
  const res = await get(`${productPlacementEndpointPath}/products?${params}`);
  return await res.json();
};

export const getProductsDetails = async (productsIds: string[]): Promise<any> => {
  const res = await post(`${productPlacementEndpointPath}/products/details`, productsIds);
  return await res.json();
};
