import { initAuthorizationToken } from "../utils/token";
import { ideoPlatformUrl } from "./apiDetails";

export const getAuthorizationToken = (payload: any) => {
  return fetch(`${ideoPlatformUrl}/user/authenticate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: initAuthorizationToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((resposne) => resposne.json());
};

export const exchangeRefreshToken = (refreshToken: string) => {
  return fetch(`${ideoPlatformUrl}/user/refresh_token?refresh_token=${refreshToken}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: initAuthorizationToken,
      "Content-Type": "application/json",
    },
  }).then((resposne) => resposne.json());
};
