import { FC, useState } from "react";
import "./VideoGallery.sass";
import VideoPlayerModal from "../VideoPlayer/VideoPlayerModal";
import { Thumbnail } from "./Thumbnail";

type TProps = {
  videos: any;
  enableFeedback: boolean;
  sessionId: string;
  ui: boolean;
};

const VideoGallery: FC<TProps> = ({ videos, enableFeedback, sessionId, ui }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="gallery">
      <div className="thumbnails">
        {videos.map((video: any, index: number) => (
          <Thumbnail
            key={`vid-${index}`}
            video={video}
            callback={() => {
              setSelectedVideo(video);
              openModal();
            }}
          />
        ))}
      </div>
      <VideoPlayerModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        selectedVideo={selectedVideo}
        enableFeedback={enableFeedback}
        sessionId={sessionId}
        ui={ui}
      />
    </div>
  );
};

export default VideoGallery;
