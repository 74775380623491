import { userFeedbackEndpointPath, get, post, put } from "./apiDetails";

export const postFeedback = async (payload: any): Promise<Response> => {
  const res = await post(`${userFeedbackEndpointPath}/feedbacks`, payload);
  return await res.json();
};

export const putFeedback = async (queryId: string, payload: any): Promise<Response> => {
  const res = await put(`${userFeedbackEndpointPath}/feedbacks/${queryId}`, payload);
  return await res.json();
};

export const getFeedbacks = async (demo: string): Promise<Response> => {
  const res = await get(`${userFeedbackEndpointPath}/feedbacks?demo=${demo}`);
  return await res.json();
};
