import { FC, MouseEventHandler, RefObject } from "react";
import "./OrderPanelWrapper.sass";
import { OrderPanelWrapper } from "./OrderPanelWrapper";

type TProps = {
  onClick: MouseEventHandler<HTMLDivElement>;
  videoRef: RefObject<HTMLVideoElement>;
};

export const OrderPanel: FC<TProps> = ({ onClick, videoRef }) => {
  return (
    <div className="OrderPanelWrapper" onClick={onClick}>
      <OrderPanelWrapper videoRef={videoRef} />
    </div>
  );
};
