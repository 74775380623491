import { FC, FormEvent, useState, useEffect } from "react";
import "./QueryPanel.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextInput } from "../Inputs/TextInput/TextInput";
import { RadioGroup } from "../Inputs/RadioGroup/RadioGroup";
import { SubmitButton } from "../Buttons/SubmitButton/SubmitButton";
import { VoiceChat } from "../Voice/VoiceChat/VoiceChat";
import { Checkbox } from "../Inputs/Checkbox/Checkbox";
import { Answer } from "../Answer/Answer";
import { postFeedback, putFeedback } from "../../api/feedback";
import { searchMovie, TActionType } from "../../api/movieSearch";
import { getUsername } from "../../utils/token";
import { formatSecondsToTimestamp } from "../../utils/formatters";
import { v4 as uuidv4 } from "uuid";

type TProps = {
  videoRef: any;
  videoId: string;
  enableFeedback: boolean;
  sessionId: string;
};

const defaultFeedbackOptions = [
  "(A) The submitted answer is a subset of the expert answer and is fully consistent with it.",
  "(B) The submitted answer is a superset of the expert answer and is fully consistent with it.",
  "(C) The submitted answer contains all the same details as the expert answer.",
  "(D) There is a disagreement between the submitted answer and the expert answer.",
  "(E) The answers differ, but these differences don't matter from the perspective of factuality.",
];

const jumpFeedbackOptions = [
  "(A) The submitted timestamp is completely missed and too soon.",
  "(B) The submitted timestamp is slightly missed and too soon, but it doesn't matter from the perspective of usability.",
  "(C) The submitted timestamp is correct.",
  "(D) The submitted timestamp is slightly missed and too late, but it doesn't matter from the perspective of usability.",
  "(E) The submitted timestamp is completely missed and too late.",
  "(F) The submitted timestamp class is undefined.",
];

const feedbackOptionsByActionType = {
  chat_action: defaultFeedbackOptions,
  jump_action: jumpFeedbackOptions,
  show_part_action: jumpFeedbackOptions,
  summary_action: defaultFeedbackOptions,
};

const answerEvaluationType = {
  chat_action: "chat_answer_evaluation",
  show_part_action: "show_part_answer_evaluation",
  summary_action: "summary_answer_evaluation",
  jump_action: "jump_answer_evaluation",
};

const demoName = "Finding Scenes";
let queryId: string = ""; // TODO - move to findingScenesSlice
let showPartIntervalId: number;

export const QueryPanel: FC<TProps> = ({ videoRef, videoId, enableFeedback, sessionId }) => {
  const [query, setQuery] = useState<string>("");
  const [noSpoilers, setNoSpoilers] = useState<boolean>(true);
  const [expertAnswer, setExpertAnswer] = useState<string>("");
  const [descriptiveFeedback, setDescriptiveFeedback] = useState<string>("");
  const [answerScore, setAnswerScore] = useState<string>("");
  const [answerScoreDesc, setAnswerScoreDesc] = useState<string>("");
  const [isSubmittingQuery, setIsSubmittingQuery] = useState<boolean>(false);
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState<boolean>(false);
  const [showQueryPanel, setShowQueryPanel] = useState(false);
  const [isFeedbackRequired, setIsFeedbackRequired] = useState(false);
  const [actionType, setActionType] = useState<TActionType | null>(null);
  const [textAnswer, setTextAnswer] = useState<string | null>(null);

  const username = getUsername() || "";
  let pausedTimeSeconds = 0;
  const showPartIntervalCheckMs = 500;
  let showPartStartSeconds: number = 0;
  let showPartEndSeconds: number = 0;

  useEffect(() => {
    if (!videoRef.current) return;

    videoRef.current.addEventListener("pause", () => setShowQueryPanel(true));
    videoRef.current.addEventListener("play", () => cleanupOnResumePlaying());
    videoRef.current.addEventListener("seeked", () => onVideoSeeked());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef]);

  useEffect(() => {
    return () => {
      setQuery("");
      cleanupResponseAndFeedback();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanupResponseAndFeedback = () => {
    setTextAnswer(null);
    setActionType(null);
    setExpertAnswer("");
    setDescriptiveFeedback("");
    setAnswerScore("");
    setAnswerScoreDesc("");
    pausedTimeSeconds = 0;
  };

  const cleanupFeedbackFields = () => {
    setQuery("");
    cleanupResponseAndFeedback();
  };

  const cleanupOnResumePlaying = () => {
    setShowQueryPanel(false);
    if (!actionType) {
      return;
    } else if (actionType === "chat_action" || actionType === "summary_action") {
      cleanupFeedbackFields();
      setIsFeedbackRequired(false);
    }
  };

  const currentTimeOutsideOfShowPartScene = (currentTime: number) =>
    showPartStartSeconds &&
    showPartEndSeconds &&
    (currentTime < showPartStartSeconds || currentTime > showPartEndSeconds);

  const currentTimeRightAfterShowPartEnd = (currentTime: number) => {
    const maxWindowSeconds = (showPartIntervalCheckMs * 4) / 1000;
    return currentTime >= showPartEndSeconds && currentTime <= showPartEndSeconds + maxWindowSeconds;
  };

  const onVideoSeeked = () => {
    if (currentTimeOutsideOfShowPartScene(videoRef.current.currentTime)) {
      resetShowPartInterval();
    }
  };

  const resetShowPartInterval = () => {
    clearInterval(showPartIntervalId);
    showPartIntervalId = 0;
    showPartStartSeconds = 0;
    showPartEndSeconds = 0;
  };

  const handleClosePanel = () => {
    cleanupOnResumePlaying();
    videoRef.current.play();
    cleanupFeedbackFields();
    setIsFeedbackRequired(false);
  };

  const handleQueryChange = (value: string) => {
    setQuery(value);
    cleanupResponseAndFeedback();
  };

  const handleSubmitQuery = (event: FormEvent) => {
    event.preventDefault();
    submitQuery();
    setIsFeedbackRequired(false);
  };

  const saveInputArgsFeedback = () => {
    const payload = {
      demo: demoName,
      query_id: queryId,
      session_id: sessionId,
      user_id: username,
      input_args: JSON.stringify({
        query_id: queryId,
        user_id: username,
        session_id: sessionId,
        user_query: query,
        current_content_id: videoId,
        current_playback_ts: formatSecondsToTimestamp(videoRef.current.currentTime),
        content_length: formatSecondsToTimestamp(videoRef.current.duration),
        extra_args: {
          no_spoil_mode: noSpoilers,
        },
      }),
    };
    postFeedback(payload).catch((error) => {
      console.error("Error posting feedback for question: ", error);
      toast(`❌ Error on posting feedback for question: ${error}`, { autoClose: false });
    });
  };

  const saveResponseFeedback = (response: any) => {
    const payload = {
      demo: demoName,
      query_id: queryId,
      user_id: username,
      session_id: sessionId,
      ideo_response: JSON.stringify({
        query_id: queryId,
        session_id: sessionId,
        user_id: username,
        action: response.action,
        debugInfo: response.debug_info,
      }),
    };
    putFeedback(queryId, payload).catch((error) => {
      console.error("Error putting feedback for response: ", error);
      toast(`❌ Error on putting feedback for response: ${error}`, { autoClose: false });
    });
  };

  const submitQuery = () => {
    if (isSubmittingQuery) return;
    setIsSubmittingQuery(true);
    setTextAnswer(null);
    resetShowPartInterval();
    queryId = uuidv4();
    const addDebugInfo = enableFeedback;
    if (enableFeedback) {
      saveInputArgsFeedback();
    }

    searchMovie(
      query,
      videoRef.current.currentTime,
      noSpoilers,
      queryId,
      sessionId,
      username,
      videoId,
      videoRef.current.duration,
      addDebugInfo
    )
      .then((response: any) => {
        setTextAnswer(response?.action?.text_summary || response?.action?.response || null);
        const action = response.action;
        setActionType(action.action_type);
        if (enableFeedback) {
          saveResponseFeedback(response);
        }
        if (action.action_type === "jump_action" || action.action_type === "show_part_action") {
          if (enableFeedback) {
            setIsFeedbackRequired(true);
          }
          setShowQueryPanel(false);
          if (action.action_type === "jump_action") {
            videoRef.current.currentTime = action.timestamp_ms / 1000;
          } else if (action.action_type === "show_part_action") {
            pausedTimeSeconds = videoRef.current.currentTime;
            showPartStartSeconds = action.start_timestamp_ms / 1000;
            showPartEndSeconds = action.end_timestamp_ms / 1000;
            videoRef.current.currentTime = showPartStartSeconds;

            showPartIntervalId = window.setInterval(() => {
              if (currentTimeOutsideOfShowPartScene(videoRef.current.currentTime)) {
                if (currentTimeRightAfterShowPartEnd(videoRef.current.currentTime)) {
                  showModalAfterShowPart();
                }
                resetShowPartInterval();
              }
            }, showPartIntervalCheckMs);
          }
          videoRef.current.play();
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        toast(`❌ Error on sending request: ${error}`, { autoClose: false });
      })
      .finally(() => {
        setIsSubmittingQuery(false);
      });
  };

  const showModalAfterShowPart = () => {
    videoRef.current.pause();
    if (window.confirm("Scene finished - do you want to go back?")) {
      videoRef.current.currentTime = pausedTimeSeconds;
      pausedTimeSeconds = 0;
    }
    videoRef.current.play();
  };

  const handleSubmitFeedback = (event: FormEvent) => {
    setIsSubmittingFeedback(true);
    event.preventDefault();
    const payload = {
      demo: demoName,
      query_id: queryId,
      session_id: sessionId,
      user_id: username,
      golden_answer: expertAnswer,
      answer_evaluation: JSON.stringify({
        classification_result: answerScore,
        classification_result_desc: answerScoreDesc,
        answer_evaluation_type: actionType ? answerEvaluationType[actionType] : "",
        descriptive_feedback: descriptiveFeedback,
      }),
    };
    putFeedback(queryId, payload)
      .then((response: any) => {
        toast("✅ Feedback sent successfully", { autoClose: false });
      })
      .catch((error) => {
        console.error("Error putting feedback: ", error);
        toast("❌ Error sending feedback", { autoClose: false });
      })
      .finally(() => {
        setIsSubmittingFeedback(false);
      });
  };

  const handleFeedbackButton = () => {
    videoRef.current.pause();
    setIsFeedbackRequired(false);
    setShowQueryPanel(true);
  };

  const getAnswerScore = (answer: string): string => {
    return answer.split(" ")[0].replace(/[^A-Z]/g, "");
  };

  const feedbackPanel = (feedbackOptionsByActionType: any) => {
    return (
      <div className="row">
        <form onSubmit={handleSubmitFeedback} className="form-vertical">
          {/* check form values - if fields are set/selected */}
          {/* https://tcl-lab.atlassian.net/browse/IRD-260 */}
          {textAnswer && (
            <TextInput
              value={expertAnswer}
              placeholder="Type preferred answer..."
              onChange={(e) => setExpertAnswer(e.target.value)}
              required={answerScore !== "" && answerScore !== "C"}
            />
          )}
          <RadioGroup
            name="feedback"
            labels={actionType ? feedbackOptionsByActionType[actionType] : []}
            values={actionType ? feedbackOptionsByActionType[actionType] : []}
            onChange={(e) => {
              setAnswerScore(getAnswerScore(e.target.value));
              setAnswerScoreDesc(e.target.value);
            }}
          />
          <TextInput
            value={descriptiveFeedback}
            placeholder="Type descriptive feedback"
            onChange={(e) => setDescriptiveFeedback(e.target.value)}
            required={false}
          />
          <SubmitButton text="Send feedback" isSubmitting={isSubmittingFeedback} disabled={isSubmittingFeedback} />
        </form>
      </div>
    );
  };

  if (showQueryPanel) {
    return (
      <div className="QueryPanel" onClick={(e) => e.stopPropagation()}>
        <div className="panel-content">
          <div className="row">
            <Checkbox label="No spoilers" checked={noSpoilers} onChange={(e) => setNoSpoilers((state) => !state)} />
          </div>
          <div className="row">
            <form onSubmit={handleSubmitQuery} className="form">
              <TextInput
                value={query}
                placeholder="Ask question..."
                onChange={(e) => handleQueryChange(e.target.value)}
                required
              />
              <VoiceChat
                updateTranscript={setQuery}
                onFinishedListening={submitQuery}
                listenOnStart={false}
                continousListening={false}
                textToSpeak={textAnswer}
                endingPhrases={["thanks", "thank you"]}
              />
              <SubmitButton text="Ask" isSubmitting={isSubmittingQuery} disabled={isSubmittingQuery} />
            </form>
          </div>
          <div className="row">
            <Answer text={textAnswer} />
          </div>
          {enableFeedback && actionType && feedbackPanel(feedbackOptionsByActionType)}
          <FontAwesomeIcon onClick={() => handleClosePanel()} className="close-panel-button" icon={faXmark} />
        </div>
      </div>
    );
  }

  if (isFeedbackRequired) {
    return (
      <button className="feedback-button" onClick={handleFeedbackButton}>
        Send Feedback
      </button>
    );
  }

  return null;
};
