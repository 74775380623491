export const addParams = (url: string, params: string[][]) => {
  if (!params.length) {
    return url;
  }

  const parametersString = params.reduce((stringParams, [key, value]) => {
    return `${stringParams}&${key}=${value}`;
  }, "");

  return `${url}?${parametersString}`;
};
