import { TCardPaymentMethod } from "../api/payment";
import { getUsername } from "./token";

export const getMockEMail = () => `${getUsername()}@mock.com`;
export const getMockCardData = (): TCardPaymentMethod => ({
  card_number: 5555555555554444,
  expiration_year: 2034,
  expiration_month: 12,
  cvc: "123",
});

export const chatQuestionMocks = [
  {
    key: "p",
    question: "What does Emma want from Dracula?",
    response: "Initially, Emma wants fake vampire teeth from Dracula, thinking he is a costume store.",
  },
  {
    key: "a",
    question: "Why is the family in egypt?",
    response:
      "The family ended up in Egypt because the witch Baba Yaga teleported them there with a spell after they confronted her in London. She did this to prevent them from forcing her to change them back from the monster forms she had previously transformed them into. Based on the dialogue, she sent them to Egypt unintentionally, as she was trying to send them somewhere far away and Egypt was a mistaken destination.",
  },
  {
    key: "s",
    question: "How did the family get to egypt?",
    response: "The witch Baba Yaga transported the family to Egypt with her magic after they confronted her in London.",
  },
  {
    key: "d",
    question: "How can the Wishbones become humans again?",
    response: "The Wishbones can become human again when they are happy.",
  },
  {
    key: "f",
    question: "What can the snowflake do?",
    response:
      "The snowflake can be engineered into a deadly weapon that can destroy the sun and plunge the Earth into a deadly ice age.",
  },
  {
    key: "g",
    question: "Why Emma leaves her family?",
    response:
      "Emma leaves her family because she is tempted by Dracula's offer of an exciting new life together. However, in the end she realizes her love for her family is more important.",
  },
  {
    key: "h",
    question: "Where does the family go from the desert to?",
    response: "The family goes from the desert to Dracula's castle.",
  },
  {
    key: "j",
    question: "Who is Imhotep?",
    response:
      "Imhotep is a mummy that Fay meets in the desert. He is looking surprisingly youthful and cute, almost like a teenager. He tells Fay that he is the Pharaoh Imhotep and that he has lived for over 3000 years. He calls Fay beautiful and says he has been waiting for a woman like her. However, he later reveals that he wants Fay to help him conquer the world, which makes her uneasy. Imhotep has magical powers and can turn into a whirlwind. He fights the Wishbone family when they refuse to let him take Fay.",
  },
  {
    key: "k",
    question: "What monster did Frank fight?",
    response: "Frank fought a giant beetle monster.",
  },
  {
    key: "l",
    question: "How did the family break the spell?",
    response: "The family broke the spell by finally becoming happy together.",
  },
  {
    key: "z",
    question: "What is the name of the movie?",
    response: "The name of the movie is 'The Spooky Tale of Captain Underpants Hack-a-ween'.",
  },
  {
    key: "x",
    question: "Who is Cheyenne?",
    response:
      "Cheyenne is a character in the movie 'The Spooky Tale of Captain Underpants Hack-a-ween'. She is a young girl who befriends the Wishbone family and helps them on their quest to break the curse that has turned them into monsters.",
  },
  {
    key: "c",
    question: "Who is Dracula?",
    response:
      "Dracula is a character in the movie 'The Spooky Tale of Captain Underpants Hack-a-ween'. He is a vampire who wants to make Emma his bride and is the one who asks Baba Yaga to curse her.",
  },
  {
    key: "v",
    question: "Who is Fay?",
    response:
      "Fay is a character in the movie 'The Spooky Tale of Captain Underpants Hack-a-ween'. She is Emma's sister and is turned into a mummy by Baba Yaga's curse.",
  },
  {
    key: "b",
    question: "Who is Max?",
    response:
      "Max is a character in the movie 'The Spooky Tale of Captain Underpants Hack-a-ween'. He is Emma's brother and is turned into a werewolf by Baba Yaga's curse.",
  },
  {
    key: "n",
    question: "Who is Frank?",
    response:
      "Frank is a character in the movie 'The Spooky Tale of Captain Underpants Hack-a-ween'. He is Emma's father and is turned into a Frankenstein monster by Baba Yaga's curse.",
  },
];

export const chatJumpsMocks = [
  {
    key: "q",
    question: "Jump to the opening scene",
    timestamp: 12083,
  },
  {
    key: "w",
    question: "Jump to the moment where Baba Yaga transforms family into monsters",
    timestamp: 1112320,
  },
  {
    key: "e",
    question: "Jump to the part where Emma is transformed into vampire",
    timestamp: 242784,
  },
  {
    key: "r",
    question: "Go to the moment where dracula meets Emma",
    timestamp: 242784,
  },
  {
    key: "t",
    question: "Go to the scene at the oasis in the desert",
    timestamp: 3001536,
  },
  {
    key: "y",
    question: "Jump to the part where Wishbones get to London",
    timestamp: 2673202,
  },
  {
    key: "u",
    question: "Jump to the part where Emma gets to Oculus Londra",
    timestamp: 2797413,
  },
  {
    key: "i",
    question: "Show me the moment where Wishbones fight dracula",
    timestamp: 4996867,
  },
  {
    key: "o",
    question: "Show me the moment the Wishbones escape from the chains",
    timestamp: 4945482,
  },
];

export const jumpMovieMocks = [
  {
    key: "1",
    timestamp: 12083,
  },
  {
    key: "2",
    timestamp: 1112320,
  },
  {
    key: "3",
    timestamp: 242784,
  },
  {
    key: "4",
    timestamp: 3001536,
  },
  {
    key: "5",
    timestamp: 2673202,
  },
  {
    key: "6",
    timestamp: 2797413,
  },
  {
    key: "7",
    timestamp: 4996867,
  },
  {
    key: "8",
    timestamp: 4945482,
  },
  {
    key: "9",
    timestamp: 146813,
  },
  {
    key: "0",
    timestamp: 1279195,
  },
];

export const romanceInHawaiiMovieDetails = {
  id: "19283745",
  title: "Romance In Hawaii",
  media:
    "https://tcl-channel-cdn.ideonow.com/AVOD/HLS/ID821592/Romance_In_Hawaii_Feature_1080p_Eng20_Clean_Screener-838882671.m3u8",
};

// TODO: move it to env var
export const moviesIds = [
  "ID166666", // Cold Moon - no rights
  "ID307457", // Dear Santa
  "ID885862", // Wardens s09 e01
  "ID259672", // Wardens s09 e09
  "ID757229", // My classic car s23 e01
  "ID486474", // Mexico One Plate at a Time s08 e01
  "ID062081", // Artificial Immortality
  "ID821592", // Romance in Hawaii
  "ID182246", // A Father’s Nightmare
  // "ID150908", // Monster Family - offline
];

export const featuredMovieId = moviesIds[6];
