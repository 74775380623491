import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./BackButton.sass";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const BackButton: FC = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return <FontAwesomeIcon onClick={() => goBack()} className="BackButton" icon={faArrowLeft} />;
};
