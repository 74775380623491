import { ChangeEventHandler, FC, useId } from "react";
import "./Checkbox.sass";

type TProps = {
  label: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const Checkbox: FC<TProps> = ({ checked, label, onChange }) => {
  const id = useId();
  return (
    <>
      <input type="checkbox" id={id} defaultChecked={checked} onChange={onChange} className="Checkbox" />
      <label htmlFor={id}>{label}</label>
    </>
  );
};
