import { ChangeEvent, FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight, faUtensils } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import "./OrderPickerItem.sass";
import { TProduct } from "../../../api/products";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../app/store";
import { addOrderItem, removeOrderItem } from "../productPlacementSlice";

type TProps = {
  product: TProduct;
};

export const OrderPickerItem: FC<TProps> = ({ product: { thumbnail_url, name, quantity_available, product_id } }) => {
  const dispatch = useDispatch();
  const order = useSelector<TRootState, { [key: string]: number }>((state) => state.productPlacement.order);
  const productCount = order[product_id] || 0;
  const [isFocused, setFocused] = useState<boolean>(false);

  const handleAmountChange = (value: number) => {
    if (!value) {
      dispatch(removeOrderItem(product_id));
    } else {
      dispatch(addOrderItem({ [product_id]: value }));
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = +e.target.value;
    if (inputValue >= 0) {
      handleAmountChange(inputValue);
    }
  };

  const imageClassName = classnames("meal-image", {
    selected: !!productCount,
  });

  return (
    <div className="OrderPickerItem">
      {thumbnail_url ? (
        <img className={imageClassName} src={thumbnail_url} alt={name} />
      ) : (
        <div className={imageClassName}>
          <FontAwesomeIcon icon={faUtensils} />
        </div>
      )}
      <div className="product-name">{name}</div>
      <div className="picker-controls">
        <FontAwesomeIcon
          className={classnames("left", { focused: isFocused })}
          icon={faCaretLeft}
          onClick={() => (productCount > 0 ? handleAmountChange(productCount - 1) : "")}
        />
        <input
          type="number"
          value={productCount}
          onChange={handleInputChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          max={quantity_available || 10}
        />
        <FontAwesomeIcon
          className={classnames("right", { focused: isFocused })}
          icon={faCaretRight}
          onClick={() => handleAmountChange(productCount + 1)}
        />
      </div>
    </div>
  );
};
