import { useEffect, Dispatch, FC, useState, RefObject, useCallback } from "react";
import { faFlask } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./MockChat.sass";
import { chatJumpsMocks, chatQuestionMocks } from "../../../utils/mocks";

type TProps = {
  setQuery: Dispatch<React.SetStateAction<string>>;
  setTranscriptTransactionInProgress: Dispatch<React.SetStateAction<boolean>>;
  setResponse: Dispatch<React.SetStateAction<string>>;
  videoRef: RefObject<HTMLVideoElement>;
  submitQuery: () => void;
  query: string;
};

type TChatMock = {
  key: string;
  question: string;
  response: string;
};

type TJumpMock = {
  key: string;
  question: string;
  timestamp: number;
};

export const MockChat: FC<TProps> = ({
  videoRef,
  setQuery,
  setTranscriptTransactionInProgress,
  setResponse,
  submitQuery,
  query,
}) => {
  const video = videoRef.current;

  const [shouldMockReponse, setShouldMockReponse] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [keyDown, setKeyDown] = useState<number>(0);

  const [chatMocks, setChatMocks] = useState<TChatMock[]>([]);
  const [jumpMocks, setJumpMocks] = useState<TJumpMock[]>([]);

  const [newChatKey, setNewChatKey] = useState<string>("");
  const [newChatQuestion, setNewChatQuestion] = useState<string>("");
  const [newChatAnswer, setNewChatAnswer] = useState<string>("");

  const [newJumpKey, setNewJumpKey] = useState<string>("");
  const [newJumpQuestion, setNewJumpQuestion] = useState<string>("");
  const [newJumpTimestamp, setNewJumpTimestamp] = useState<number>(0);

  const mockResponse = (responseText: string) => {
    setTranscriptTransactionInProgress(true);
    setTimeout(() => {
      setTranscriptTransactionInProgress(false);
      setResponse(responseText);
    }, 2000);
  };

  const mockJumpResponse = (timestamp: number) => {
    setTranscriptTransactionInProgress(true);
    setTimeout(() => {
      setTranscriptTransactionInProgress(false);
      setResponse("OK, let's jump to that moment.");
      if (video) {
        video.currentTime = timestamp / 1000;
        video.play();
      }
    }, 3000);
  };

  useEffect(() => {
    if (query) {
      submitQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyDown]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      chatMocks.forEach(({ key, question, response }) => {
        if (event.key === key) {
          setQuery(question);

          if (shouldMockReponse) {
            mockResponse(response);
          } else {
            setKeyDown(keyDown + 1);
          }
        }
      });

      jumpMocks.forEach(({ key, question, timestamp }) => {
        if (event.key === key) {
          setQuery(question);

          if (shouldMockReponse) {
            mockJumpResponse(timestamp);
          } else {
            setKeyDown(keyDown + 1);
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatMocks, jumpMocks, keyDown, shouldMockReponse]
  );

  const addNewMockChat = () => {
    const newChats = [
      ...chatMocks,
      {
        key: newChatKey,
        question: newChatQuestion,
        response: newChatAnswer,
      },
    ];
    localStorage.setItem("chatMocks", JSON.stringify(newChats));
    setChatMocks(newChats);
  };

  const addNewMockJump = () => {
    const newJumps = [
      ...jumpMocks,
      {
        key: newJumpKey,
        question: newJumpQuestion,
        timestamp: newJumpTimestamp,
      },
    ];
    localStorage.setItem("jumpMocks", JSON.stringify(newJumps));
    setJumpMocks(newJumps);
  };

  const resetAll = () => {
    localStorage.setItem("chatMocks", JSON.stringify([]));
    localStorage.setItem("jumpMocks", JSON.stringify([]));
    setChatMocks([]);
    setJumpMocks([]);
  };

  const loadPresets = () => {
    setChatMocks(chatQuestionMocks);
    setJumpMocks(chatJumpsMocks);

    localStorage.setItem("chatMocks", JSON.stringify(chatQuestionMocks));
    localStorage.setItem("jumpMocks", JSON.stringify(chatJumpsMocks));
  };

  const setMockResponses = () => {
    setShouldMockReponse(!shouldMockReponse);
  };

  useEffect(() => {
    const chatMocks = JSON.parse(localStorage.getItem("chatMocks") || "[]");
    const jumpMocks = JSON.parse(localStorage.getItem("jumpMocks") || "[]");
    setChatMocks(chatMocks);
    setJumpMocks(jumpMocks);
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleKeyPress]);

  return (
    <div className="MockChat">
      {open ? (
        <div>
          <div className="chats-preview">
            {chatMocks.map(({ key, question, response }) => (
              <div className="mock-chat-item" key={key}>
                <p>KEY: {key}</p>
                <p>Me: {question}</p>
                <p>Resposne: {response}</p>
              </div>
            ))}
            {jumpMocks.map(({ key, question, timestamp }) => (
              <div className="mock-jump-item" key={key}>
                <p>KEY: {key}</p>
                <p>Me: {question}</p>
                <p>Jump timestamp: {timestamp}</p>
              </div>
            ))}
          </div>
          <div className="mock-chat-item">
            <input type="text" placeholder="Key from QA" onChange={(e) => setNewChatKey(e.target.value)} />
            <input type="text" placeholder="Question" onChange={(e) => setNewChatQuestion(e.target.value)} />
            <input type="text" placeholder="Answer" onChange={(e) => setNewChatAnswer(e.target.value)} />
            <input type="button" value="Add" onClick={addNewMockChat} />
          </div>
          <div className="mock-jump-item">
            <input type="text" placeholder="Key for Jump" onChange={(e) => setNewJumpKey(e.target.value)} />
            <input type="text" placeholder="Question" onChange={(e) => setNewJumpQuestion(e.target.value)} />
            <input
              type="text"
              placeholder="Jump to time (e.g. 1811182)"
              onChange={(e) => setNewJumpTimestamp(+e.target.value)}
            />
            <input type="button" value="Add" onClick={addNewMockJump} />
          </div>
          <p className="clear" onClick={setMockResponses}>
            {shouldMockReponse ? "Disable mock response" : "Enable mock response"}
          </p>
          <p className="clear" onClick={loadPresets}>
            Load mocks presets
          </p>
          <p className="clear" onClick={resetAll}>
            Reset All
          </p>
          <p className="close" onClick={() => setOpen(false)}>
            Close
          </p>
        </div>
      ) : (
        <FontAwesomeIcon className="experiment-icon" icon={faFlask} onClick={() => setOpen(true)} />
      )}
    </div>
  );
};
