import jwt_decode from "jwt-decode";

export const initAuthorizationToken = "Wm9vbTpab29tU2VjcmV0";

export type TAuthorizationResponse = {
  access_token: string;
  expires_in: number;
  jti: string;
  refresh_token: string;
  scope: string;
  token_type: string;
};

export type TToken = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
};

export type TJWT = {
  aud: string[];
  user_name: string;
  scope: string[];
  exp: number;
  authorities: string[];
  jti: string;
  client_id: string;
};

export const setToken = ({ access_token, refresh_token, expires_in }: TToken) => {
  localStorage.setItem("access_token", access_token);
  localStorage.setItem("refresh_token", refresh_token);
  localStorage.setItem("expires_in", String(Date.now() + expires_in * 1000));
};

export const removeToken = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expires_in");
};

export const getUserToken = () => {
  return localStorage.getItem("access_token");
};

export const getToken = () => {
  const token = getUserToken();
  return `bearer ${token}`;
};

export const hasTokenSet = () => {
  const token = localStorage.getItem("access_token");
  return !!token;
};

export const isTokenExpired = (): boolean => {
  const expiresIn = (localStorage.getItem("expires_in") || 0) as number;
  const currentDateTime = new Date();
  return expiresIn <= currentDateTime.valueOf();
};

export const getRefreshToken = (): string => {
  const refreshToken = localStorage.getItem("refresh_token");
  return refreshToken || "";
};

export const getUsername = (): string | null => {
  const token = getUserToken() || "";
  try {
    return jwt_decode<TJWT>(token).user_name;
  } catch (Error) {
    return null;
  }
};
