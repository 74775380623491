import { FC, useCallback, useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { toggleFullscreen } from "../../../utils/fullscreen";
import { QueryPanel } from "../../../componentsShared/QueryPanel/QueryPanel";
import "./VideoPlayer.sass";
import { PauseNavigationOverlay } from "../PauseNavigationOverlay/PauseNavigationOverlay";
import { jumpMovieMocks } from "../../../utils/mocks";

type TProps = {
  url: string;
  title: string;
  videoId: string;
  style: any;
  enableFeedback: boolean;
  sessionId: string;
  ui: boolean;
};

const VideoPlayer: FC<TProps> = ({ url, title, videoId, enableFeedback, sessionId, ui }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreen = () => {
    toggleFullscreen(containerRef);
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    if (!videoRef.current) return;
    const video: any = videoRef.current;
    video.autoplay = true;

    if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = url;
    } else if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(video);
    } else {
      console.error("This is a legacy browser that doesn't support MSE");
    }

    return () => {
      if (video) {
        video.src = "";
        video.removeAttribute("src");
      }
    };
  }, [url]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      jumpMovieMocks.forEach(({ key, timestamp }) => {
        if (event.key === key) {
          const video: any = videoRef.current;
          if (video) {
            video.currentTime = timestamp / 1000;
            console.log(event.key, timestamp);
          }
        }
      });
    },
    [videoRef]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleKeyPress]);

  return (
    <div ref={containerRef} className="VideoPlayer">
      {ui ? (
        <PauseNavigationOverlay videoRef={videoRef} title={title} videoId={videoId} />
      ) : (
        <QueryPanel videoRef={videoRef} videoId={videoId} enableFeedback={enableFeedback} sessionId={sessionId} />
      )}
      <video ref={videoRef} controls className="video-player" />
      <FontAwesomeIcon
        onClick={handleFullscreen}
        icon={faExpand}
        className={`fullscreen-button ${isFullscreen ? "fullscreen" : ""}`}
      />
    </div>
  );
};

export default VideoPlayer;
