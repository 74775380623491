import { getToken, getUsername } from "../utils/token";
import { addParams } from "../utils/url";

// APIs
export const ideoPlatformUrl = `${process.env.REACT_APP_IDEO_PLATFORM_URL}`;
export const ideoS3BucketUrl = `${process.env.REACT_APP_IDEO_STATIC_DATA_URL}`;

// Endpoints/Paths
export const productPlacementEndpointPath = `${process.env.REACT_APP_PRODUCT_PLACEMENTS_API_PATH}`;
export const userFeedbackEndpointPath = `${process.env.REACT_APP_USER_FEEDBACK_API_PATH}`;
export const movieSearchEndpointPath = `${process.env.REACT_APP_MOVIE_SEARCH_API_PATH}`;
export const videosEndpointPath = "api/metadata/v1";
export const videosTestEndpointPath = "api/protectedmetadata/v1/vod/programs/detail";

export const getCommonHeaders = () => ({
  Accept: "application/json",
  Authorization: getToken(),
  "Content-Type": "application/json",
});

export const get = (path: string, params: string[][] = []) => {
  const url = `${ideoPlatformUrl}/${path}`;
  const urlWithParams = addParams(url, params);

  return fetch(addUserIdParameter(urlWithParams), {
    method: "GET",
    headers: getCommonHeaders(),
  });
};

export const post = (path: string, payload: any) => {
  return fetch(addUserIdParameter(`${ideoPlatformUrl}/${path}`), {
    method: "POST",
    headers: getCommonHeaders(),
    body: JSON.stringify(payload),
  });
};

export const put = (path: string, payload: any) => {
  return fetch(addUserIdParameter(`${ideoPlatformUrl}/${path}`), {
    method: "PUT",
    headers: getCommonHeaders(),
    body: JSON.stringify(payload),
  });
};

export const addUserIdParameter = (path: string): string => {
  return `${path}${path.includes("?") ? "&" : "?"}userId=${getUsername()}`;
};
