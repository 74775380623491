// @ts-nocheck
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import VideoPlayer from "./VideoPlayer";
import "./VideoPlayerModal.sass";

Modal.setAppElement("#root");

const VideoPlayerModal = ({ isOpen, closeModal, selectedVideo, enableFeedback, sessionId, ui }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} className="VideoPlayerModal" contentLabel="Video Player">
      {selectedVideo && (
        <VideoPlayer
          url={selectedVideo.media}
          title={selectedVideo.title}
          videoId={selectedVideo.bundle_id}
          enableFeedback={enableFeedback}
          sessionId={sessionId}
          ui={ui}
        />
      )}
      <FontAwesomeIcon onClick={closeModal} className="close-button" icon={faXmark} />
    </Modal>
  );
};

export default VideoPlayerModal;
