import { productPlacementEndpointPath, get } from "./apiDetails";

export type TPlacement = {
  start_time_sec: 3879;
  end_time_sec: 3891;
  message: string;
  keyword: string;
};

export const getPlacements = async (videoId: string): Promise<TPlacement[]> => {
  const res = await get(`${productPlacementEndpointPath}/placements?movie_id=${videoId}`);
  return await res.json();
};
