import "./PrototypesList.sass";
import { ApplicationRoutes } from "../../app/routes";
import { Link } from "../../componentsShared/Link/Link";
import { DiscreetText } from "../../componentsShared/DiscreetText/DiscreetText";

type TLink = {
  name: string;
  route?: ApplicationRoutes;
  description?: string;
};

export const PrototypesList = () => {
  const links: TLink[] = [
    {
      name: "Product Placement",
      description: "Purchase items featured in a movie.",
      route: ApplicationRoutes.productPlacement,
    },
    {
      name: "Finding Scenes",
      description: "Get answers to plot-related questions during the screening.",
      route: ApplicationRoutes.findingScenes,
    },
    {
      name: "Finding Scenes - User Feedback",
      description: "Evaluate answers to plot-related questions during the screening.",
      route: ApplicationRoutes.findingScenesUserFeedback,
    },
    {
      name: "Finding Scenes - Demo",
      description: "New UI with Buddy Assistant",
      route: ApplicationRoutes.findingScenesUI,
    },
  ];

  return (
    <div className="PrototypesList">
      {links.map((link: TLink, index: number) => (
        <div key={`prototype-link-${index}`} className="link-container">
          <Link {...link} />
          <DiscreetText text={link.description || ""} />
        </div>
      ))}
    </div>
  );
};
