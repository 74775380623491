import { FC, MouseEventHandler } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./GoBack.sass";

type TProps = {
  onClick: MouseEventHandler<HTMLDivElement>;
};

export const GoBack: FC<TProps> = ({ onClick }) => {
  return (
    <div className="GoBack" onClick={onClick}>
      <p>BACK</p>
      <FontAwesomeIcon icon={faCircleArrowLeft} />
      <div className="c1" />
      <div className="c2" />
      <div className="c3" />
    </div>
  );
};
