import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../app/store";
import { MakingOrderStep, setProductPlacementStep } from "./productPlacementSlice";
import { orderFlow } from "./flow";

export const GoBackButton = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector<TRootState, MakingOrderStep | null>((state) => state.productPlacement.step);

  const handleOnClick = () => {
    if (!currentStep) {
      return;
    }

    const prevStepIndex = orderFlow.indexOf(currentStep) - 1;
    const prevStep = prevStepIndex < 0 ? null : orderFlow[prevStepIndex];
    dispatch(setProductPlacementStep(prevStep));
  };

  if (currentStep && [MakingOrderStep.Payment, MakingOrderStep.Summary].includes(currentStep)) {
    return null;
  }

  return (
    <div className="GoBackButton" onClick={handleOnClick}>
      Tap Back button twice to end this function
    </div>
  );
};
