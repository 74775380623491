import { FC } from "react";
import "./DiscreetText.sass";

type TProps = {
  text: string;
};

export const DiscreetText: FC<TProps> = ({ text }) => {
  return <p className="DiscreetText">{text}</p>;
};
