import { movieSearchEndpointPath, get } from "./apiDetails";

export type TActionType = "chat_action" | "jump_action" | "show_part_action" | "summary_action";

export type TAnswer = {
  action: {
    action_type: TActionType;
    text_summary?: string;
    timestamp_ms?: number | null;
    response?: string;
    start_timestamp_ms?: number;
    end_timestamp_ms?: number;
  };
  debug_info: string | null;
};

export const searchMovie = async (
  question: string,
  currentTimeSeconds: number,
  noSpoilers: boolean,
  queryId: string,
  sessionId: string,
  userId: string,
  currentContentId: string,
  contentLength: number,
  addDebugInfo: boolean
): Promise<Response> => {
  const params = new URLSearchParams({
    question: question,
    current_time_seconds: currentTimeSeconds.toString(),
    no_spoilers: noSpoilers.toString(),
    query_id: queryId,
    session_id: sessionId,
    add_debug_info: addDebugInfo.toString(),
    user_id: userId,
    current_content_id: currentContentId,
    content_length: contentLength.toString(),
  }).toString();

  const res = await get(`${movieSearchEndpointPath}/movie-search?${params}`);
  return await res.json();
};
