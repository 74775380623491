import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar, faStarHalfStroke } from "@fortawesome/free-regular-svg-icons";
import { FC } from "react";

type TProps = {
  rating: number;
  className?: string;
};

export const Stars: FC<TProps> = ({ rating, className }) => {
  const fullStarsCount = Math.floor(rating);
  const halfStarsCount = rating % 1 !== 0 ? 1 : 0;
  const emptyStarsCount = 5 - fullStarsCount - halfStarsCount;

  return (
    <div className={className}>
      {Array.from(Array(fullStarsCount)).map((_, index) => (
        <FontAwesomeIcon key={`full-star-${index}`} icon={faStarSolid} color="orange" />
      ))}
      {halfStarsCount ? <FontAwesomeIcon icon={faStarHalfStroke} color="orange" /> : null}
      {Array.from(Array(emptyStarsCount)).map((_, index) => (
        <FontAwesomeIcon key={`empty-star-${index}`} icon={faStar} color="orange" />
      ))}
    </div>
  );
};
