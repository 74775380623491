import { FC, useEffect, useState } from "react";
import "./OrderPlacementList.sass";
import { TPlace } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { pickPlace, setPlaces } from "../productPlacementSlice";
import { TRootState } from "../../../app/store";
import { getPlacesProducts } from "../helpers";
import { TPlacement } from "../../../api/placements";
import { OrderPlacementList } from "./OrderPlacementList";
import { OrderPlacement } from "./OrderPlacement";
import { Spinner } from "../../../componentsShared/Spinner/Spinner";

type TProps = {};

export const OrderPlacementWrapper: FC<TProps> = () => {
  const dispatch = useDispatch();
  const places = useSelector<TRootState, TPlace[]>((state) => state.productPlacement.places);
  const currentPlacement = useSelector<TRootState, TPlacement | null>(
    (state) => state.productPlacement.currentPlacement
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (currentPlacement) {
      getPlacesProducts(currentPlacement).then((places: TPlace[]) => {
        dispatch(setPlaces(places));
        dispatch(pickPlace(places[0].place_id));
        setLoading(false);
      });
    }
  }, [dispatch, currentPlacement]);

  if (loading) {
    return <Spinner isOverlay />;
  }

  return (
    <div className="order-list" onClick={(e) => e.stopPropagation()}>
      <OrderPlacementList places={places} />
      <OrderPlacement />
    </div>
  );
};
