import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../components/Auth/authSlice";
import productPlacementReducer from "../components/ProductPlacement/productPlacementSlice";
import findingScenesReducer from "../components/FindingScenes/findingScenesSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    productPlacement: productPlacementReducer,
    findingScenes: findingScenesReducer,
  },
});

export type TRootState = ReturnType<typeof store.getState>;
export default store;
