import { FC } from "react";
import classnames from "classnames";
import { Stars } from "./Stars";
import { currentDay } from "../../../utils/dates";
import "./OrderPlacementList.sass";
import { TPlace } from "../types";
import { MAX_PLACES_SHOWN } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { pickPlace } from "../productPlacementSlice";
import { TRootState } from "../../../app/store";

type TProps = {
  places: TPlace[];
};

export const OrderPlacementList: FC<TProps> = ({ places }) => {
  const dispatch = useDispatch();
  const placePicked = useSelector<TRootState, string | undefined>((state) => state.productPlacement.placePicked);
  const todayDayIndex = new Date().getDay();
  const currentDayName = currentDay[todayDayIndex];

  return (
    <div className="OrderPlacementList">
      {places.slice(0, MAX_PLACES_SHOWN).map((restaurant: TPlace, index: number) => (
        <div
          key={restaurant.place_id}
          onClick={() => dispatch(pickPlace(restaurant.place_id))}
          className={classnames("restaurant", { selected: placePicked === restaurant.place_id })}
        >
          <div className="thumbnail-container">
            {restaurant.thumbnail_url && (
              <img className="restaurant-thumbnail" src={restaurant.thumbnail_url} alt="Restaurant thumbnail" />
            )}
          </div>
          <div>
            <div className="strong-text">
              {index + 1}. {restaurant.name}
            </div>
            <div className="stars-container">
              <Stars key={`star-${index}`} rating={restaurant.rating as number} className="stars" />
              <div className="rating-count">{restaurant.reviews_count}</div>
            </div>
            <div className="restaurant-tags-container">
              {restaurant.tags.slice(0, 5).map((tag: string, i: number) => (
                <span key={i} className="restaurant-tag">
                  {tag}
                </span>
              ))}
              {restaurant.tags.length > 5 && <span className="restaurant-tag">...</span>}
            </div>
            <div className="opening-info">Opening hours: {restaurant.opening_hours[currentDayName] || " -"}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
