import React, { FC, ReactNode, useEffect } from "react";
import {
  TAuthorizationResponse,
  getRefreshToken,
  hasTokenSet,
  isTokenExpired,
  removeToken,
  setToken,
} from "../../utils/token";
import { ApplicationRoutes } from "../../app/routes";
import { Navigate } from "react-router-dom";
import { exchangeRefreshToken } from "../../api/auth";

type TProps = {
  children: ReactNode;
};

const NoTokenRedirect = <Navigate to={ApplicationRoutes.main} />;

export const AuthorizedOnly: FC<TProps> = ({ children }) => {
  useEffect(() => {
    const isExpired = isTokenExpired();

    if (isExpired) {
      const refreshToken = getRefreshToken();

      if (refreshToken) {
        exchangeRefreshToken(refreshToken)
          .then(({ access_token, refresh_token, expires_in }: TAuthorizationResponse) => {
            setToken({ access_token, refresh_token, expires_in });
            window.location.reload();
          })
          .catch((error) => {
            console.error(error);
            removeToken();
          });
      }
    }
  }, []);

  const token = hasTokenSet();

  if (!token) {
    return NoTokenRedirect;
  }

  return <>{children}</>;
};
