import { FC } from "react";
import "./ErrorNotification.sass";

type TProps = {
  text: string;
};

export const ErrorNotification: FC<TProps> = ({ text }) => {
  return <p className="ErrorNotification">{text}</p>;
};
