import { PrototypesList } from "../components/PrototypesList/PrototypesList";
import { Header } from "../componentsShared/Header/Header";
import { Layout } from "../componentsShared/Layout/Layout";
import { AuthorizedOnly } from "../components/Auth/AuthorizedOnly";

export const PrototypesListPage = () => {
  return (
    <AuthorizedOnly>
      <Layout>
        <Header />
        <PrototypesList />
      </Layout>
    </AuthorizedOnly>
  );
};
