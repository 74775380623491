import "./Header.sass";
import TclLogo from "../../assets/images/tcl_logo.png";
import IdeoLogo from "../../assets/images/ideo_logo.png";
import { Logout } from "../Logout/Logout";

export const Header = () => {
  return (
    <header className="Header">
      <div className="logos">
        <img className="ideo-logo" src={IdeoLogo} alt="ideo-logo" />
        <img className="tcl-logo" src={TclLogo} alt="tcl-logo" />
      </div>
      <nav>
        <Logout />
      </nav>
    </header>
  );
};
