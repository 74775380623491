import { FC } from "react";
import classname from "classnames";
import { ApplicationRoutes } from "../../app/routes";
import { useNavigate } from "react-router-dom";
import "./Link.sass";

type TProps = {
  name: string;
  route?: ApplicationRoutes;
};

export const Link: FC<TProps> = ({ name, route }) => {
  const navigate = useNavigate();

  return (
    <button className={classname("Link", { dimmed: !route })} onClick={() => (route ? navigate(route) : "")}>
      {name}
    </button>
  );
};
